import { ReactI18NextChild, useTranslation } from 'react-i18next';
import {
  UserInfoList_Table_Column,
  UserInfoList_Table_Column_Edu,
} from './columns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// AG-GRID
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import {
  CellClickedEvent,
  ColDef,
  GridReadyEvent,
  IDatasource,
  RowDropZoneParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
//import { UserInfoList_Table_Column } from './columns';
// 컴포넌트
import {
  adminUserListApi,
  userListApi,
} from '../../../api/admin/api-admin-user';
import RightTopBar from '../../mainPage/organization_user_management/RightTopBar';
// 리덕스
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  initSelectedUser,
  setDeptNameAndCode,
  setSelectedUser,
  setSelectedUsers,
} from '../../../redux/slice/organizationUserManagementSlice';
import {
  deptListToKeyValue,
  getAllDeptList,
} from '../../../helpers/h-orgUserMng';
import axios from 'axios';
import {
  closeModifyUserModal,
  closeUserOrgMngModal,
  openModifyUserModal,
  openUserOrgMngModal,
} from '../../../helpers/h-modals';
import { toast } from 'react-toastify';
import { setDraggedItem } from '../../../redux/slice/dragDropSlice';

interface Props {
  width?: number | undefined;
}

const OrgUserMng_Table = ({ width }: Props) => {
  const userType = useAppSelector((state) => state.user.type);
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const dispatch = useAppDispatch();
  const { addDeptStatus, deptStatus, removeDeptStatus } = useAppSelector(
    (state) => state.organizationUserManagement.orgInfo,
  );
  const { userAddStatus, userModifyStatus } = useAppSelector(
    (state) => state.organizationUserManagement.userInfo,
  );

  // AG_GRID
  const gridRef = useRef<any>();
  const [gridApi, setGridApi] = useState<any>();
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 100,
      //dndSource: true
    };
  }, []);
  // 테이블 인피니트 스크롤용
  let page = 1;
  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      userListApi({ page })
        .then((resp) => ({
          userList: resp?.data.data.result,
          totpage: resp?.data.data.totpage,
          totcount: resp?.data.data.totcount,
        }))
        .then((data) => {
          if (!data) return;
          if (!data.totcount || !data.totpage || !data.userList) return;
          const lastPage =
            page < data.totpage ? page * 50 + 1 : parseInt(data.totcount);
          params.successCallback(data.userList, lastPage);
          page++;
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            console.log('error: ', error);
          } else {
            console.log('unexpected error: ', error);
          }
        });
    },
  };

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
    params.api.setDatasource(dataSource);
    addDropZones(params);
  }, []);

  //셀 클릭
  const cellClickedListener = useCallback((event: CellClickedEvent<any>) => {
    const userInfo = event.data;
    console.log(userInfo);
    dispatch(setSelectedUser(userInfo));
  }, []);

  //셀렉션
  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    dispatch(setSelectedUsers(selectedRows));
  }, []);

  //더블클릭
  const rowDoubleClickedListener = useCallback(
    (event: CellClickedEvent<any>) => {
      openModifyUserModal();
    },
    [],
  );

  // 사용자 모달 부서 추가할때 리스트를 위한 함수
  const fetchDeptList = async () => {
    const deptList = await getAllDeptList();
    console.log(deptList);
    if (!deptList) return;

    const deptKeyVal = deptListToKeyValue(deptList);

    if (!deptKeyVal) return;

    dispatch(setDeptNameAndCode(deptKeyVal));
  };

  const addDropZones = useCallback(
    (params: {
      api: {
        addRowDropZone: (arg0: {
          getContainer: () => Element | null;
          onDragStop: (event: any) => void;
        }) => void;
      };
    }) => {
      const dropZone = {
        getContainer: () => document.querySelector('#treeView'),
        onDragStop: (event: {
          nodes: {
            data: {
              bid: string;
              deptcode: string;
              deptname: string;
              position: string;
              username: string;
            };
          }[];
        }) => {
          const draggedData = event.nodes.map(
            (node: {
              data: {
                bid: string;
                deptcode: string;
                deptname: string;
                position: string;
                username: string;
              };
            }) => ({
              bid: node.data.bid,
              deptcode: node.data.deptcode,
              deptname: node.data.deptname,
              position: node.data.position,
              username: node.data.username,
            }),
          );
          dispatch(setDraggedItem(draggedData));
        },
      };
      params.api.addRowDropZone(dropZone);
    },
    [dispatch],
  );

  // const onRowDragStart = (event: any) => {
  //   // 로그 출력으로 확인
  //   //console.log('Drag start event:', event);
  //   //console.log('Node data:', event.api);
  //   console.log('Node data:', event.api.getSelectedNodes());

  //   event.api.getSelectedNodes().forEach((node: any) => {
  //     console.log(JSON.stringify(node.data));
  //   }, []);

  //   // if (event.node && event.node.data) {
  //   //   const draggedData = event.node.data;
  //   //   event.api.clearFocusedCell(); // 포커스된 셀 초기화

  //   //   // JSON 형식으로 데이터를 전송할 준비
  //   //   event.dataTransfer.setData('text/plain', JSON.stringify(draggedData));
  //   // } else {
  //   //   console.error('No data available to drag');
  //   // }
  // };

  const onRowDragStart = (event: any) => {
    const selectedNodes = event.api.getSelectedNodes();
    if (selectedNodes.length > 0) {
      const draggedData = selectedNodes.map((node: { data: any }) => node.data);
      //console.log(draggedData);
      event.api.clearFocusedCell(); // 포커스된 셀 초기화

      //console.log(event.dataTransfer);
      // JSON 형식으로 데이터를 전송할 준비
      //event.dataTransfer.setData('text/plain', JSON.stringify(draggedData));
    } else {
      console.error('No data available to drag');
    }
  };

  // 사용자 정보 변경시 사용자 리스트 다시 불러오기
  useEffect(() => {
    if (userAddStatus === 'success' || userModifyStatus === 'success') {
      if (!gridApi) return;
      gridApi.setDatasource(dataSource);
      //gridApi.addRowDropZone(dropZoneParams);
    }
  }, [userAddStatus, userModifyStatus]);

  // 부서 정보 변경시 부서 리스트 다시 불러오기
  useEffect(() => {
    if (addDeptStatus !== 'success') return;
    fetchDeptList();
  }, [addDeptStatus]);

  useEffect(() => {
    if (removeDeptStatus !== 'success') return;
    if (!gridApi) return;
    gridApi.setDatasource(dataSource);
    fetchDeptList();
  }, [removeDeptStatus]);

  useEffect(() => {
    if (deptStatus !== 'success') return;
    if (!gridApi) return;
    gridApi.setDatasource(dataSource);
    fetchDeptList();
  }, [deptStatus]);

  // 첫 렌더링시 전체 사용자 조회
  useEffect(() => {
    fetchDeptList();
  }, []);

  // 클리어 함수
  useEffect(() => {
    return () => {
      dispatch(initSelectedUser());
    };
  }, []);

  useEffect(() => {
    if (userType === 'edu') {
      setColumnDefs(UserInfoList_Table_Column_Edu(t));
    } else {
      setColumnDefs(UserInfoList_Table_Column(t));
    }
  }, [t]);

  //esc 이벤트
  useEffect(() => {
    const handleKeyDown = (event: { key: string }) => {
      if (event.key === 'Escape') {
        closeModifyUserModal();
        closeUserOrgMngModal();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="w-full grow flex flex-col overflow-hidden">
      <RightTopBar width={width} gridApi={gridApi} />
      <div className="ag-theme-alpine ag-theme-mycustomtheme grow w-full">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={'multiple'}
          rowDragManaged={true}
          suppressRowDrag={false}
          onCellClicked={cellClickedListener}
          rowModelType={'infinite'}
          cacheBlockSize={50}
          onRowDoubleClicked={rowDoubleClickedListener}
          overlayNoRowsTemplate={'\xa0'}
          onSelectionChanged={onSelectionChanged} // 여기에 이벤트 리스너 추가
          onDragStarted={onRowDragStart}
          //onRowDragEnter={onRowDragStart }
          rowDragEntireRow={true}
          rowDragMultiRow={true}
          //rowDragText={rowDragText}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default OrgUserMng_Table;
