import NoImageUserAvatar from '../assets/image/profile_photo.png';
import { TreeNodeProps } from 'rc-tree';
import DefaultAvatar from '../components/common/icons/DefaultAvatar';
import SelectDown from '../components/common/icons/SelectDown';
import { TreeData } from './../components/treeView/TreeViewV6';
import { useCallback } from 'react';
import {
  IhrsOrgDeptFull,
  IhrsOrgUserAll,
} from '../interfaces/api/I-api-hrs-orgs';
import {
  IclondeNodeWrapper,
  IdropIndicatorRender,
} from '../interfaces/I-treeView';
export const a = 'a';

export const customTitle = (node: TreeData) => {
  const Title =
    node.data.type === 'hrsOrgDept' ? (
      // 부서
      <div className="truncate py-[0.375rem] pointer-events-none" aria-grabbed>
        {node.title}
      </div>
    ) : (
      //개발용
      // <div className="truncate py-[0.375rem] pointer-events-none" aria-grabbed>
      //   {node.title} ({node.data.deptpath}){' '}
      //   <span className="">deptorder: {node.data.deptorder}</span>
      // </div>
      // 사용자
      <div className="py-[0.375rem] flex grow pointer-events-none">
        {/* <div className="ml-6 w-11 h-11 mr-3"> */}
        <div className="w-11 h-11 mr-3">
          {node.data.picurl ? (
            <img
              src={applyThumbor(node.data.picurl)}
              alt="img"
              className="w-full h-full rounded-full"
            />
          ) : (
            <img
              src={NoImageUserAvatar}
              alt="img"
              className="w-full h-full rounded-full"
            />
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex">
            <div className="mr-3 font-bold shrink-0">{node.title}</div>{' '}
            <div
              className="mr-3 t
            ext-[#676767] shrink-0"
            >
              {node.data.position}
            </div>
            {/* 개발용 */}
            {/* <span className="text-xs jcac shrink-0">
              userorder: {node.data.userorder}
            </span> */}
          </div>
          <div className="flex">
            <div>{node.data.email}</div>
          </div>
        </div>
      </div>
    );

  return Title;
};

// 확장 아이콘
// type TswitcherIconHandler = (props: TreeNodeProps<TreeData>) => React.ReactNode;
export const switcherIconHandler = (props: TreeNodeProps) => {
  if (!props.isLeaf) {
    return (
      <SelectDown
        className={`${
          props.expanded ? '' : '-rotate-90'
        } jcac hover:bg-[#D5D7DC] rounded`}
      />
    );
  }
};

// 아이콘 eg (폴더..)
export const iconHandler = (props: TreeNodeProps) => {
  if (props.isLeaf) {
    return <div className="ucTenantTree-iconHandler relative h-16"></div>;
  }
};

// 드래그시 생기는 툴팁.
export const cloneNodeEl = (cloneNode: IhrsOrgDeptFull | IhrsOrgUserAll) => {
  let name = '';
  let picurl = '';
  if (cloneNode.type === 'hrsOrgDept') {
    name = cloneNode.deptname;
  } else {
    name = cloneNode.username;
    picurl = cloneNode.picurl;
  }
  return (
    <div className="flex items-center w-full h-full ml-6">
      {cloneNode.type === 'hrsOrgUser' && (
        <div className="w-11 h-11 mr-3">
          {picurl ? (
            <img
              src={picurl}
              alt="img"
              className="w-full h-full rounded-full"
            />
          ) : (
            <DefaultAvatar />
          )}
        </div>
      )}
      <div>{name}</div>
    </div>
  );
};

// 드래그시 생기는 툴팁 WRAPPER
export const clondeNodeWrapper = ({ x, y, cloneNode }: IclondeNodeWrapper) => {
  return (
    <div
      style={{
        width: '300px',
        height: '50px',
        backgroundColor: 'white',
        color: 'black',
        border: '1px black solid',
        fontSize: '1.3rem',
        position: 'fixed',
        left: x,
        top: y,
        zIndex: '9999',
        transform: 'translate(10%, -50%)',
        pointerEvents: 'none',
      }}
    >
      {cloneNodeEl(cloneNode)}
    </div>
  );
};

// 드랍 목적지 ui
// 1 = dropToGap
// 0 = dropToBottom
export const dropIndicatorRender = (props: IdropIndicatorRender) => {
  let indentX;

  if (props.dropPosition === 0) {
    indentX = 60;
  } else if (props.dropLevelOffset > 0) {
    indentX = (props.dropLevelOffset + 1) * -24;
  } else {
    indentX = 0;
  }

  return (
    <div
      className="ucTenantTree-dropIndicator"
      style={{
        pointerEvents: 'none',
        position: 'absolute',
        right: '0px',
        backgroundColor: '#1f62b9',
        height: '2px',
        bottom: '0px',
        left: `${indentX}px`,
        // left: '2px',
      }}
    ></div>
  );
};

// 모션 애니메이션
export const motion = {
  motionName: 'node-motion',
  motionAppear: false,
  onAppearStart: (node: any) => {
    // console.log('Start Motion:', node);
    return { height: 0 };
  },
  onAppearActive: (node: any) => ({ height: node.scrollHeight }),
  onLeaveStart: (node: any) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 }),
};

const applyThumbor = (imageUrl: string) => {
  const thumborServerUrl = 'https://thumbor.ucworks-dev.net/unsafe/';
  const size = '44x44';
  return `${thumborServerUrl}${size}/${encodeURIComponent(imageUrl)}`;
};
