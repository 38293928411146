import { ColDef, ValueGetterParams } from 'ag-grid-community';
import {
  AgCellTextCenter,
  userField5CellRenderer,
} from '../../common/AgCustomCell';
import { AgHeaderTextCenter } from '../../common/AgCustomHeader';
import { IUserInfo } from '../../../interfaces/api/I-api-hrs-users';

const dateValueGetter = (params: ValueGetterParams<any>) => {
  if (!params.data) return '?';

  const year = params.data.updatetime.slice(0, 4);
  const month = params.data.updatetime.slice(4, 6);
  const day = params.data.updatetime.slice(6, 8);
  const hour = params.data.updatetime.slice(8, 10);
  const min = params.data.updatetime.slice(10, 12);
  const sec = params.data.updatetime.slice(12, 14);

  const dayTimeFormat = `${year}-${month}-${day} ${hour}:${min}:${sec}`;
  return dayTimeFormat;
};

//교육용 컬럼
export const UserInfoList_Table_Column_Edu = (t: (key: string) => string) => {
  return [
    {
      field: 'userid',
      headerName: t('OrganizationUserManagement.h-userid'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'username',
      headerName: t('OrganizationUserManagement.h-userName'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'deptname',
      headerName: t('OrganizationUserManagement.h-deptName'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'userfield5',
      headerName: t('OrganizationUserManagement.h-role'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: (params: any) => userField5CellRenderer({ ...params, t }),
    },
    {
      field: 'position',
      headerName: t('OrganizationUserManagement.h-position'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'telmobile',
      headerName: t('OrganizationUserManagement.h-telMobile'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'teloffice',
      headerName: t('OrganizationUserManagement.h-telOffice'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'email',
      headerName: t('OrganizationUserManagement.h-email'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'updatetime',
      headerName: t('OrganizationUserManagement.h-updateTime'),
      valueGetter: dateValueGetter,
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
  ];
};

//기업용 컬럼
export const UserInfoList_Table_Column = (t: (key: string) => string) => {
  return [
    {
      field: 'userid',
      headerName: t('OrganizationUserManagement.h-userid'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'username',
      headerName: t('OrganizationUserManagement.h-userName'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'deptname',
      headerName: t('OrganizationUserManagement.h-deptName'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'position',
      headerName: t('OrganizationUserManagement.h-position'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'duty',
      headerName: t('OrganizationUserManagement.h-duty'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'telmobile',
      headerName: t('OrganizationUserManagement.h-telMobile'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'teloffice',
      headerName: t('OrganizationUserManagement.h-telOffice'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'email',
      headerName: t('OrganizationUserManagement.h-email'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'updatetime',
      headerName: t('OrganizationUserManagement.h-updateTime'),
      valueGetter: dateValueGetter,
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
  ];
};

//일괄등록
export const UserInfoList_Table_Import_Column = (
  t: (key: string) => string,
) => {
  return [
    {
      field: 'checkbox',
      headerName: ' ',
      // headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      resizable: false,
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'deptcode',
      headerName: t('OrganizationUserManagement.h-deptCode'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'deptname',
      headerName: t('OrganizationUserManagement.h-deptName2'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'parentdeptcode',
      headerName: t('OrganizationUserManagement.h-upDeptCode'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'deptorder',
      cellEditor: 'numericCellEditor',
      headerName: t('OrganizationUserManagement.h-deptOrder'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
  ];
};

export const UserInfoList_Table_Import_User_Column = (
  t: (key: string) => string,
) => {
  return [
    {
      field: 'checkbox',
      headerName: ' ',
      // headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      resizable: false,
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'deptcode',
      headerName: t('OrganizationUserManagement.h-uDeptCode'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'userid',
      headerName: t('OrganizationUserManagement.h-uUserId'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'userpwd',
      headerName: t('OrganizationUserManagement.h-uUserPwd'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'usernameKo',
      headerName: t('OrganizationUserManagement.h-uUserName'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'usernameEn',
      headerName: t('OrganizationUserManagement.h-uUserNameEn'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
    {
      field: 'position',
      headerName: t('OrganizationUserManagement.h-uPosition'),
      headerComponent: AgHeaderTextCenter,
      cellRenderer: AgCellTextCenter<any>,
    },
  ];
};
