import '../../../styles/react-resizable.css';
import React, { ReactElement, useEffect, useState } from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import hint from '../../../assets/image/etc/hint.png';
import 'react-tooltip/dist/react-tooltip.css';
import Button from '../../common/buttons/Button';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { toast } from 'react-toastify';
import NoImageUserAvatar from '../../../assets/image/profile_photo.png';
import { openProfileImg } from '../../../helpers/h-react-modals';
import { FormikProps, Formik, Form, Field, useFormikContext } from 'formik';
import { Tooltip } from 'react-tooltip';
import { modifyFormSchema } from '../../../helpers/h-formSchema';
import { selectedUserToForm, modifyUserHandler } from '../../../helpers/h-orgUserMng';
import { IadminUserListApiResponse, IUserFormData } from '../../../interfaces/api/I-api-admin-users';
import { setProfileUrl } from '../../../redux/slice/profileSlice';
import InputTextFormik from '../../common/inputs/InputTextFormik';
import * as yup from 'yup';
import { closeClassParentInfoModal } from '../../../helpers/h-react-modals';
import Modal from 'react-modal';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { getMyTenantId } from '../../../helpers/h-userInfo';
import { IhrsUserListApiResponseFull, IUserInfo } from '../../../interfaces/api/I-api-hrs-users';
import { hrsUserListApi } from '../../../api/hrs/api-hrs-users';
import { parentList, parentRemoveClass } from '../../../api/admin/class/api-class';
import { IRelationDeletePayload } from '../../../interfaces/api/I-api-class';
import { clearSelectedRelation, setStudentReloadStatus } from '../../../redux/slice/classManagementSlice';

const customStyles = {
  overlay: {
    zIndex: 99,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '668px',
    padding: 0,
  },
};

interface Props {
  isOpen: boolean;
}

const ClassParentInfoModal: React.FC<Props> = ({ isOpen = false }) => {
  const { t, ready } = useTranslation();
  const [formSchema, setFormSchema] = useState<yup.ObjectSchema<any> | null>(null);
  const dispatch = useAppDispatch();
  const selectedParentId = useSelector((state: RootState) => state.classManagement.selectedParentId);
  const selectedStudentId = useSelector((state: RootState) => state.classManagement.selectedStudentId);
  const tenantid = getMyTenantId();
  const { usersid } = useAppSelector((state) => state.user);
  const [selectedUsers, setSelectedUsers] = useState<IadminUserListApiResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [profilePicUrl, setProfilePicUrl] = useState(NoImageUserAvatar);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!selectedParentId) return;

      setLoading(true);
      try {
        const bid = `${tenantid}.${selectedParentId}`;
        const commonRs = await hrsUserListApi({
          cmd: '1101',
          sid: usersid || '',
          who: [{ bid }],
          language: 'ko',
          fields: 'FULL',
        });

        if (commonRs?.data?.data?.result) {
          const convertedUser = convertHrsToAdminUser(commonRs.data.data.result[0]);
          console.log(convertedUser);
          setSelectedUsers(convertedUser);
        } else {
          toast.error(String(t('ClassManagement.classModalMsg1')));
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error(String(t('ClassManagement.classDetailSel10')));
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [selectedParentId]);

  const openProfileImgFn = (imageUrl: string) => {
    dispatch(setProfileUrl(imageUrl));
    console.log(imageUrl);
    dispatch(openProfileImg());
  };

  useEffect(() => {
    if (ready) {
      setFormSchema(modifyFormSchema(t));
    }
  }, [t, ready]);

  useEffect(() => {
    console.log(selectedUsers); // 상태가 올바르게 업데이트되는지 확인
  }, [selectedUsers]);

  const convertHrsToAdminUser = (user: IhrsUserListApiResponseFull): IadminUserListApiResponse => {
    return {
      ...user,
      userpwd: '', // 필요한 경우 기본값으로 설정
      userpwdhash: '',
      userpwdhashtype: '',
      updatetype: '',
      updatetime: '',
    };
  };

  const handleClear = async () => {
    if (!selectedStudentId || !selectedParentId) return;

    const studentIds = [selectedStudentId];
    const rs = await parentList({ studentIds });

    if (!rs?.data.result) return;

    const uids = rs.data.result
      .filter((relation) => relation.studentId === selectedStudentId && relation.parentId === selectedParentId)
      .map((relation) => relation.uid);

    const payload: IRelationDeletePayload = { uids };
    const removeRs = await parentRemoveClass(payload);

    console.log(removeRs?.data.result.count);

    if (removeRs?.data.result.count > 0) {
      toast.success(String(t('ClassManagement.classModalMsg2')));
    } else {
      toast.error(String(t('ClassManagement.classModalMsg3')));
    }
    dispatch(clearSelectedRelation());
    dispatch(setStudentReloadStatus(true));
    closeClassParentInfoModal();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeClassParentInfoModal} style={customStyles} contentLabel="Example Modal">
      {/* WRAPPER */}
      <div
        className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
        style={{ width: `auto`, height: `auto` }}
      >
        {/* HEADER */}
        <div id="handle" className="min-h-[3.5rem] cursor-move w-full flex flex-col relative">
          <div className="grow font-bold w-full flex items-center truncate">{t('ClassManagement.classParentInfo')}</div>
        </div>
        {/* BODY */}
        {selectedUsers && (
          <Formik
            initialValues={selectedUserToForm(selectedUsers)}
            enableReinitialize={true} // 이 속성 추가
            validationSchema={formSchema}
            onSubmit={(values) => {
              modifyUserHandler(values);
            }}
          >
            {(formik: FormikProps<IUserFormData>) => {
              return (
                <Form className="grow w-full flex flex-col justify-between overflow-y-auto">
                  <div className="grow w-full flex flex-col gap-3">
                    {/* 프로필 사진 */}
                    <div className="relative m-auto mt-1 mb-1 w-28 h-28">
                      <img
                        key={formik.values.picurl ? applyThumbor(formik.values.picurl) : profilePicUrl}
                        src={formik.values.picurl ? applyThumbor(formik.values.picurl) : profilePicUrl}
                        alt="Profile"
                        className="w-full h-full rounded-full"
                        onClick={() =>
                          openProfileImgFn(formik.values.picurl ? applyThumborV2(formik.values.picurl) : profilePicUrl)
                        }
                      />
                    </div>
                    <div className={`text-gray-400 text-sm`}>{t('ClassManagement.classModalDesc')}</div>
                    <CustomCell
                      title={t('OrganizationUserManagement.userId')}
                      content={<Field component={InputTextFormik} disabled name={`userid`} value={`userid`} />}
                    />
                    <CustomCell
                      title={t('OrganizationUserManagement.name')}
                      content={
                        <Field
                          component={InputTextFormik}
                          disabled
                          name={`usernameKo`}
                          value={`usernameKo`}
                          handleClear={() => {
                            formik.setFieldValue(`usernameKo`, '');
                          }}
                        />
                      }
                    />
                    <CustomCell
                      title={t('OrganizationUserManagement.email')}
                      content={
                        <Field
                          disabled
                          component={InputTextFormik}
                          name="email"
                          value="email"
                          optionsHeight={12}
                          width={'100%'}
                        />
                      }
                    />
                    <CustomCell
                      title={t('OrganizationUserManagement.department')}
                      content={
                        <Field
                          disabled
                          component={InputTextFormik}
                          name="deptname"
                          value="deptname"
                          optionsHeight={12}
                          width={'100%'}
                        />
                      }
                    />
                    <CustomCell
                      title={t('OrganizationUserManagement.position')}
                      content={
                        <Field
                          disabled
                          component={InputTextFormik}
                          name={`position`}
                          value={`position`}
                          handleClear={() => {
                            formik.setFieldValue(`position`, '');
                          }}
                        />
                      }
                    />
                  </div>
                  {/* 취소 / 저장 버튼 */}
                  <div className="min-h-[6rem] w-full  jcac">
                    <div className="flex gap-2">
                      <Button kind="cancel" label={t('OrganizationUserManagement.cancel')} />
                      <Button onClick={handleClear} kind="primary" label={t('ClassManagement.classModalClear')} />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </Modal>
  );
};

interface ICustomCell {
  title: string;
  content: ReactElement;
  required?: boolean;
  isDate?: boolean;
  last?: boolean;
  isToolTip?: boolean;
}
const CustomCell = ({ title, required, isDate, content, last, isToolTip }: ICustomCell) => {
  return (
    <div
      className="w-full"
      style={{
        zIndex: isDate ? 99 : '',
      }}
    >
      {/* 제목 */}
      <div className={`w-full font-bold text-modal-subTitle ${last ? 'mt-10' : ''}`}>
        {title}
        {required && <span className="text-[#d21011]">*</span>}
        {isToolTip && (
          <img
            data-tooltip-id="usernameEn-tooltip"
            className={`ml-1 w-4.5 cursor-pointer inline-block`}
            style={{ marginTop: '-1px' }}
            src={hint}
          />
        )}
        <Tooltip
          id="usernameEn-tooltip"
          style={{
            backgroundColor: '#0055ab',
            color: '#19231b',
            zIndex: 1,
          }}
        >
          <div className={`block mt-4 mb-4 z-1 w-30 `}>
            <div className={`block text-sm mb-1 text-white`}>
              {t('OrganizationUserManagement.usernameEnTooltip') as string}
              <br />
              {t('OrganizationUserManagement.usernameEnTooltip2') as string}
            </div>
          </div>
        </Tooltip>
      </div>
      {/* INPUT */}
      <div className="w-full">{content}</div>
    </div>
  );
};

const applyThumbor = (imageUrl: string) => {
  const thumborServerUrl = 'https://thumbor.ucworks-dev.net/unsafe/';
  const size = '144x144';
  return `${thumborServerUrl}${size}/${encodeURIComponent(imageUrl)}`;
};

const applyThumborV2 = (imageUrl: string) => {
  const thumborServerUrl = 'https://thumbor.ucworks-dev.net/unsafe/';
  return `${thumborServerUrl}${encodeURIComponent(imageUrl)}`;
};

const AutoSubmitToken = ({ onClickClose }: { onClickClose: () => void }) => {
  const { userModifyStatus } = useAppSelector((state) => state.organizationUserManagement.userInfo);
  // Grab values and submitForm from context
  const event = useFormikContext<IUserFormData>();

  useEffect(() => {
    if (userModifyStatus === 'idle') return;
    if (userModifyStatus === 'fail') {
      toast.error(String(t('OrganizationUserManagement.toastFail')));
      return;
    }
    if (userModifyStatus === 'success') {
      toast.success(String(t('OrganizationUserManagement.toastModifySuccess')));
      onClickClose();
    }
  }, [userModifyStatus]);

  return null;
};

export default ClassParentInfoModal;
