import { store } from '../redux/store';

export const getMyTenantId = () => {
  const {
    user: { userInfo },
  } = store.getState();
  if (userInfo) {
    const tenantid = userInfo.bid.split('.')[0];
    return tenantid;
  } else {
    if (!sessionStorage.getItem('tenantid')) return null;
    return sessionStorage.getItem('tenantid');
  }
};

export const getMyOrgCode = () => {
  const {
    user: { userInfo },
  } = store.getState();

  if (!userInfo) return null;

  let orgcode = userInfo.orgcode;
  if (orgcode == '' || orgcode == null) orgcode = 'ORG01';
  return orgcode;
};

export const getOrgCode = () => {
  const {
    user: { userInfo },
  } = store.getState();

  if (!userInfo) return null;

  let orgcode = userInfo.orgcode;
  if (orgcode == '' || orgcode == null) orgcode = 'ORG01';
  return orgcode;
};

export const getMyRootDeptCode = () => {
  const {
    user: { userInfo },
  } = store.getState();
  if (!userInfo) return null;
  const deptcode = userInfo.rootgid.split('.')[2];
  //const deptcode = userInfo.deptpath.split(':')[0];
  return deptcode;
};

export const getMySid = () => {
  const {
    user: { usersid },
  } = store.getState();

  if (!usersid) return null;

  return usersid;
};
