import { title } from 'process';
import { ReactElement } from 'react';
import hint from '../assets/image/etc/hint.png';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { t } from 'i18next';

interface ICustomCell {
  title: string;
  content: ReactElement;
  required?: boolean;
  isDate?: boolean;
  isToolTip?: boolean;
}
export const CustomCell = ({
  title,
  required,
  isDate,
  content,
  isToolTip,
}: ICustomCell) => {
  return (
    <div
      className="w-full"
      style={{
        zIndex: isDate ? 99 : '',
      }}
    >
      {/* 제목 */}
      <div className="w-full font-bold text-modal-subTitle">
        {title}
        {required && <span className="text-[#d21011]">*</span>}
        {isToolTip && (
          <img
            data-tooltip-id="usernameEn-tooltip"
            className={`ml-1 w-4.5 cursor-pointer inline-block`}
            style={{ marginTop: '-1px' }}
            src={hint}
          />
        )}
        <Tooltip
          id="usernameEn-tooltip"
          style={{
            backgroundColor: '#0055ab',
            color: '#19231b',
            zIndex: 1,
          }}
        >
          <div className={`block mt-4 mb-4 z-1 w-30 `}>
            <div className={`block text-sm mb-1 text-white`}>
              {t('OrganizationUserManagement.usernameEnTooltip') as string}
              <br />
              {t('OrganizationUserManagement.usernameEnTooltip2') as string}
            </div>
          </div>
        </Tooltip>
      </div>
      {/* INPUT */}
      <div className="w-full">{content}</div>
    </div>
  );
};
