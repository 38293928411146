// REDUX
import { reactModalController } from '../redux/slice/toggleSlice';
import { store as rootStore } from '../redux/store';
const state = rootStore.getState();
const dispatch = rootStore.dispatch;

// 조직 / 사용자 관리 (userOrgManage)
export const openUserOrgMngReactModal_remove_dept = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'removeDept',
      value: true,
    }),
  );
export const closeUserOrgMngReactModal_remove_dept = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'removeDept',
      value: false,
    }),
  );
export const openUserOrgMngReactModal_modify_dept = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'modifyDept',
      value: true,
    }),
  );
export const closeUserOrgMngReactModal_modify_dept = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'modifyDept',
      value: false,
    }),
  );
export const openUserOrgMngReactModal_add_dept = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'addDept',
      value: true,
    }),
  );

export const closeUserOrgMngReactModal_add_dept = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'addDept',
      value: false,
    }),
  );

//관리자 권한 부여 (일반 사용자 리스트 모달)
export const openAdminIdMngUserList_Table = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'userList',
      value: true,
    }),
  );

export const closeAdminIdMngUserList_Table = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'userList',
      value: false,
    }),
  );

//조직(부서) 일괄 등록 모달
export const openBulkInsertDept_Table = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'bulkDept',
      value: true,
    }),
  );

export const closeBulkInsertDept_Table = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'bulkDept',
      value: false,
    }),
  );

//사용자 일괄 등록 모달
export const openBulkInsertUser_Table = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'bulkUser',
      value: true,
    }),
  );

export const closeBulkInsertUser_Table = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'bulkUser',
      value: false,
    }),
  );

//프로필 사진 로드
export const openProfileImg = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'profileImg',
      value: true,
    }),
  );

export const closeProfileImg = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'profileImg',
      value: false,
    }),
  );

//상담 카테고리 모달 open
export const openCounselingCategoryModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'counselingCategory',
      value: true,
    }),
  );

//상담 카테고리 모달 close
export const closeCounselingCategoryModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'counselingCategory',
      value: false,
    }),
  );

//상담 채널 모달 open
export const openCounselingChannelModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'counselingChannel',
      value: true,
    }),
  );

//상담 채널 모달 close
export const closeCounselingChannelModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'counselingChannel',
      value: false,
    }),
  );

//상담 상담사 모달 open
export const openCounselingCounselorModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'counselingCounselor',
      value: true,
    }),
  );

//상담 상담사 모달 close
export const closeCounselingCounselorModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'counselingCounselor',
      value: false,
    }),
  );

//학급관리 부서 모달 open
export const openClassDepartmentModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classDepartment',
      value: true,
    }),
  );

//학급관리 부서 모달 close
export const closeClassDepartmentModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classDepartment',
      value: false,
    }),
  );

/**
 *
 *학급 관리 모달
 *
 */

//학부모 상세 정보 모달 open
export const openClassParentInfoModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classParentInfo',
      value: true,
    }),
  );

//학부모 상세 정보 모달 close
export const closeClassParentInfoModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classParentInfo',
      value: false,
    }),
  );

//학부모 추가 모달 open
export const openClassParentModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classParent',
      value: true,
    }),
  );

//학부모 추가 모달 close
export const closeClassParentModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classParent',
      value: false,
    }),
  );

//학생 추가 모달 open
export const openClassStudentModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classStudent',
      value: true,
    }),
  );

//학생 추가 모달 close
export const closeClassStudentModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classStudent',
      value: false,
    }),
  );

//선생 추가 모달 open
export const openClassTeacherModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classTeacher',
      value: true,
    }),
  );

//선생 추가 모달 close
export const closeClassTeacherModal = () =>
  dispatch(
    reactModalController({
      name: 'orgUserMng',
      secondName: 'classTeacher',
      value: false,
    }),
  );
