// REDUX
import { modalController } from '../redux/slice/toggleSlice';
import { store as rootStore } from '../redux/store';
const state = rootStore.getState();
const dispatch = rootStore.dispatch;

// 조직 / 사용자 관리 (userOrgManage)
export const openUserOrgMngModal = () =>
  dispatch(
    modalController({
      name: 'orgUserMng',
      secondName: 'addUser',
      value: true,
    }),
  );
export const closeUserOrgMngModal = () =>
  dispatch(
    modalController({
      name: 'orgUserMng',
      secondName: 'addUser',
      value: false,
    }),
  );
export const openModifyUserModal = () =>
  dispatch(
    modalController({
      name: 'orgUserMng',
      secondName: 'modifyUser',
      value: true,
    }),
  );
export const closeModifyUserModal = () =>
  dispatch(
    modalController({
      name: 'orgUserMng',
      secondName: 'modifyUser',
      value: false,
    }),
  );

// FUNC BUTTON MNG
export const openFuncBtnGroupMainModal = () => {
  dispatch(
    modalController({
      name: 'funcBtnMng',
      secondName: 'main',
      value: true,
    }),
  );
};
export const closeFuncBtnGroupMainModal = () => {
  dispatch(
    modalController({
      name: 'funcBtnMng',
      secondName: 'main',
      value: false,
    }),
  );
};
export const openFuncBtnGroupAddModal = () => {
  dispatch(
    modalController({
      name: 'funcBtnMng',
      secondName: 'add',
      value: true,
    }),
  );
};
export const closeFuncBtnGroupAddModal = () => {
  dispatch(
    modalController({
      name: 'funcBtnMng',
      secondName: 'add',
      value: false,
    }),
  );
};
export const openFuncBtnGroupModifyModal = () => {
  dispatch(
    modalController({
      name: 'funcBtnMng',
      secondName: 'modify',
      value: true,
    }),
  );
};
export const closeFuncBtnGroupModifyModal = () => {
  dispatch(
    modalController({
      name: 'funcBtnMng',
      secondName: 'modify',
      value: false,
    }),
  );
};
export const closeAllFuncBtnModal = () => {
  closeFuncBtnGroupMainModal();
  closeFuncBtnGroupAddModal();
  closeFuncBtnGroupModifyModal();
};

// 카운트 버튼 (cntBtnMng)
export const openCntBtnGroupMainModal = () => {
  dispatch(
    modalController({
      name: 'cntBtnMng',
      secondName: 'main',
      value: true,
    }),
  );
};
export const closeCntBtnGroupMainModal = () => {
  dispatch(
    modalController({
      name: 'cntBtnMng',
      secondName: 'main',
      value: false,
    }),
  );
};
export const openCntBtnGroupAddModal = () => {
  dispatch(
    modalController({
      name: 'cntBtnMng',
      secondName: 'add',
      value: true,
    }),
  );
};
export const closeCntBtnGroupAddModal = () => {
  dispatch(
    modalController({
      name: 'cntBtnMng',
      secondName: 'add',
      value: false,
    }),
  );
};
export const openCntBtnGroupModifyModal = () => {
  dispatch(
    modalController({
      name: 'cntBtnMng',
      secondName: 'modify',
      value: true,
    }),
  );
};
export const closeCntBtnGroupModifyModal = () => {
  dispatch(
    modalController({
      name: 'cntBtnMng',
      secondName: 'modify',
      value: false,
    }),
  );
};
export const closeAllCntBtnModal = () => {
  closeCntBtnGroupMainModal();
  closeCntBtnGroupAddModal();
  closeCntBtnGroupModifyModal();
};

// rule/function 관리
export const openRuleFuncFuncModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'func',
      value: true,
    }),
  );
};
export const closeRuleFuncFuncModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'func',
      value: false,
    }),
  );
};
export const openRuleFuncRuleModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'rule',
      value: true,
    }),
  );
};
export const closeRuleFuncRuleModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'rule',
      value: false,
    }),
  );
};
export const openRuleFuncRuleAddModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'ruleAdd',
      value: true,
    }),
  );
};
export const closeRuleFuncRuleAddModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'ruleAdd',
      value: false,
    }),
  );
};
export const openRuleFuncRuleModifyModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'ruleModify',
      value: true,
    }),
  );
};
export const closeRuleFuncRuleModifyModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'ruleModify',
      value: false,
    }),
  );
};
export const openRuleFuncOptionModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'ruleOption',
      value: true,
    }),
  );
};
export const closeRuleFuncOptionModal = () => {
  dispatch(
    modalController({
      name: 'ruleFuncMng',
      secondName: 'ruleOption',
      value: false,
    }),
  );
};

export const closeAllRuleFuncModal = () => {
  closeRuleFuncFuncModal();
  closeRuleFuncRuleModal();
  closeRuleFuncRuleAddModal();
  closeRuleFuncRuleModifyModal();
};

// federation_mng
//accept
export const openFederation_accept_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'accept',
      value: true,
    }),
  );
};
export const closeFederation_accept_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'accept',
      value: false,
    }),
  );
};
// cancel
export const openFederation_cancel_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'cancel',
      value: true,
    }),
  );
};
export const closeFederation_cancel_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'cancel',
      value: false,
    }),
  );
};
// connectApply
export const openFederation_connectApply_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'connectApply',
      value: true,
    }),
  );
};
export const closeFederation_connectApply_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'connectApply',
      value: false,
    }),
  );
};
//delete
export const openFederation_delete_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'delete',
      value: true,
    }),
  );
};
export const closeFederation_delete_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'delete',
      value: false,
    }),
  );
};
// disConnectApply
export const openFederation_disConnectApply_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'disConnectApply',
      value: true,
    }),
  );
};
export const closeFederation_disConnectApply_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'disConnectApply',
      value: false,
    }),
  );
};
// reject
export const openFederation_reject_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'reject',
      value: true,
    }),
  );
};
export const closeFederation_reject_Modal = () => {
  dispatch(
    modalController({
      name: 'federationMng',
      secondName: 'reject',
      value: false,
    }),
  );
};
export const closeAllFederationMngModal = () => {
  closeFederation_cancel_Modal();
  closeFederation_connectApply_Modal();
  closeFederation_disConnectApply_Modal();
  closeFederation_accept_Modal();
  closeFederation_delete_Modal();
  closeFederation_reject_Modal();
};
