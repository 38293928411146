import MainPageTitleHeader from '../../common/MainPageTitleHeader';
import MainPageTitleHeaderDesc from '../../common/MainPageTitleHeaderDesc';
import CounselingMain from './CounselingMain';

const CounselingManagement = () => {
  return (
    <div className="w-full h-full px-[1.5rem] flex flex-col border border-solid border-[#dfe2e8] bg-white  rounded">
      <MainPageTitleHeader text="채팅 상담 관리" textOnly />
      <MainPageTitleHeaderDesc
        text={
          <>
            카테고리는 사용자의 메뉴에 반영됩니다. <br />
            채널을 카테고리 별로 추가하여, 상담사를 지정하세요.
          </>
        }
      />
      <CounselingMain />
    </div>
  );
};

export default CounselingManagement;
