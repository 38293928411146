import '../../../styles/react-resizable.css';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import * as yup from 'yup';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

// 컴포넌트
import Button from '../../common/buttons/Button';
// 라이브러리
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
// API
// 리덕스
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { initUserAddStatus } from '../../../redux/slice/organizationUserManagementSlice';
// 인터페이스
import { ResizeCallbackData } from '../../../interfaces/I-modals';
import { SelectListValue } from '../../../interfaces/TypeSelectListbox';
//formik
import { Formik, Form, Field, FormikProps, useFormikContext } from 'formik';
import InputTextFormik from '../../common/inputs/InputTextFormik';
import { IUserFormData } from '../../../interfaces/api/I-api-admin-users';
import {
  addUserHandler,
  dateToBirthDayString,
} from '../../../helpers/h-orgUserMng';
import { userFormDataInitial } from '../../../helpers/h-formInitial';
import SelectListboxFormik from '../../common/selectList/SelectListboxFormik';
import InputRadioCheckbox from '../../common/inputs/InputRadioCheckbox';
import InputDatePickerV2 from '../../common/inputs/InputDatePickerV2';
import { toast } from 'react-toastify';
import { getChildUserCountByDeptCode } from '../../../helpers/h-treeView';
import { CustomCell } from '../../../helpers/h-formik';
import { addFormSchema } from '../../../helpers/h-formSchema';
import { userIdDup } from '../../../api/hrs/api-hrs-users';
import NoImageUserAvatar from '../../../assets/image/profile_photo.png';
import { store } from '../../../redux/store';

const userpwdhashtypes = [
  { id: 'SHA256', name: 'SHA256' },
  { id: 'BCRYPT', name: 'BCRYPT' },
];

interface Props {
  onClickClose: () => void;
}
const UserOrgAddUserModal: React.FC<Props> = ({ onClickClose }) => {
  const { t, ready } = useTranslation();
  const [formSchema, setFormSchema] = useState<yup.ObjectSchema<any> | null>(
    null,
  );
  const dispatch = useAppDispatch();
  const { deptNameAndCode } = useAppSelector(
    (state) => state.organizationUserManagement.orgInfo,
  );
  const userType = useAppSelector((state) => state.user.type);

  let isFirst = true;
  const workspaceName = sessionStorage.getItem('workspacename') || ' ';
  const [deptListOptions, setDeptListOptions] = useState<SelectListValue[]>([]);
  const [selectedDept, setSelectedDept] = useState<SelectListValue>({
    id: '',
    name: '',
  });
  const [userPwdHashTypeOptions, setUserPwdHashTypeOptions] =
    useState(userpwdhashtypes);
  const [selectedUserPwdHashTypeOptions, setSelectedUserPwdHashTypeOptions] =
    useState(userpwdhashtypes[0]);
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [size, setSize] = useState({
    width: 400,
    height: 600,
  });
  const [profilePicUrl, setProfilePicUrl] = useState(NoImageUserAvatar);

  const onResize = (
    e: React.SyntheticEvent<Element, Event>,
    data: ResizeCallbackData,
  ) => {
    setSize({
      width: data.size.width,
      height: data.size.height,
    });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // 화면 처음 렌더링시.. 셀렉트 리스트의 선택 value 설정
  useEffect(() => {
    if (deptListOptions.length < 1) return;

    if (isFirst) {
      setSelectedDept(deptListOptions[0]);
      isFirst = false;
    }
  }, [deptListOptions]);

  // 셀렉트 리스트 형식 맞추기
  useEffect(() => {
    if (!deptNameAndCode) return;

    setDeptListOptions(
      deptNameAndCode.map((v: { deptcode: string; deptname: string }) => ({
        id: v.deptcode,
        name: v.deptname,
      })),
    );
  }, [deptNameAndCode]);

  // 초기화 함수
  useEffect(() => {
    return () => {
      dispatch(initUserAddStatus());
    };
  }, []);

  //다국어 처리
  useEffect(() => {
    if (ready) {
      setFormSchema(addFormSchema(t));
    }
  }, [t, ready]);

  return (
    <Draggable handle="#handle">
      <Resizable
        handle={
          <div
            className="resizable-custom-handler absolute"
            style={{
              left: size.width - 19,
              top: size.height - 19,
            }}
          ></div>
        }
        height={size.height}
        width={size.width}
        onResize={onResize}
      >
        <div className="modal-wrapper">
          {/* WRAPPER */}
          <div
            className="box drag-box  flex flex-col px-[1.25rem]
            relative border border-modal-subTitle bg-white z-50 shadow-listbox-options
            rounded
          "
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
          >
            {/* HEADER */}
            <div
              id="handle"
              className="min-h-[3.5rem] cursor-move w-full flex flex-col relative"
            >
              <div className="grow font-bold w-full flex items-center truncate">
                {t('OrganizationUserManagement.userInfo')}
              </div>
            </div>
            {/* BODY */}
            {selectedDept.id.length > 0 ? (
              <Formik
                initialValues={{
                  ...userFormDataInitial(),
                  deptcode: selectedDept.id,
                  deptname: selectedDept.name,
                  compname: workspaceName,
                  isprojectadmin: 0,
                  isreservationoperator: 0,
                  userfield5: 's',
                }}
                validationSchema={formSchema}
                onSubmit={async (values) => {
                  // alert(JSON.stringify(values, null, 2));
                  // console.log(values);
                  const userorder = await getChildUserCountByDeptCode(
                    values.deptcode,
                  );

                  if (typeof userorder !== 'number') return;

                  const user = {
                    ...values,
                    userorder,
                  };
                  addUserHandler(user);
                }}
              >
                {(formik: FormikProps<IUserFormData>) => {
                  return (
                    <Form className="grow w-full flex flex-col justify-between overflow-y-auto">
                      {/* 텍스트 */}
                      <div className="grow w-full flex flex-col gap-3 overflow-y-auto">
                        {/* 프로필 사진 */}
                        <div className="relative m-auto w-36 h-36">
                          {' '}
                          <img
                            src={profilePicUrl}
                            alt="Profile"
                            className="w-full h-full rounded-full object-cover"
                          />
                          {/* <label
                            htmlFor="file-upload"
                            className="absolute bottom-0 right-0 mb-2 mr-2 flex items-center justify-center bg-blue-500 text-white rounded-full w-8 h-8 cursor-pointer hover:bg-blue-700"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 5a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2v-1h1a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-1 .27V5zm11 2h-1v1a1 1 0 11-2 0V7h-1a1 1 0 110-2h1V4a1 1 0 112 0v1h1a1 1 0 110 2zm-7 8.414V16H4v-2.586l2-2 3 3 4-4 3 3V16h-2.586l-3-3-4 4z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <input
                              type="file"
                              id="file-upload"
                              className="hidden"
                              onChange={handleFileChange}
                            />
                          </label> */}
                        </div>
                        {/* <CustomCell
                          title={t('OrganizationUserManagement.userId')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`picurl`}
                              value={`picurl`}
                              // handleClear={() => {
                              //   formik.setFieldValue(`userid`, '');
                              // }}
                            />
                          }
                        /> */}
                        <CustomCell
                          title={t('OrganizationUserManagement.userId')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userid`}
                              value={`userid`}
                              handleClear={() => {
                                formik.setFieldValue(`userid`, '');
                              }}
                            />
                          }
                        />
                        {/* <CustomCell
                          required
                          title={t('OrganizationUserManagement.passwordEncryptMethod')}
                          content={
                            <Field
                              component={SelectListboxFormik}
                              options={userPwdHashTypeOptions}
                              selectedOption={selectedUserPwdHashTypeOptions}
                              value="userpwdhashtype"
                              name="userpwdhashtype"
                              optionsHeight={12}
                              width={'100%'}
                              onChange={(value: SelectListValue) => {
                                setSelectedUserPwdHashTypeOptions(value);
                                formik.setFieldValue(
                                  'userpwdhashtype',
                                  value.id,
                                );
                              }}
                            />
                          }
                        /> */}
                        <CustomCell
                          title={t('OrganizationUserManagement.password')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userpwd`}
                              value={`userpwd`}
                              handleClear={() => {
                                formik.setFieldValue(`userpwd`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.name')}
                          required
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`usernameKo`}
                              value={`usernameKo`}
                              handleClear={() => {
                                formik.setFieldValue(`usernameKo`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          isToolTip={true}
                          title={t('OrganizationUserManagement.nameEn')}
                          content={
                            <Field
                              component={InputTextFormik}
                              placeholder={t(
                                'OrganizationUserManagement.h-placeholder',
                              )}
                              name={`usernameEn`}
                              value={`usernameEn`}
                              handleClear={() => {
                                formik.setFieldValue(`usernameEn`, '');
                              }}
                            />
                          }
                        />
                        {/* 셀렉트 */}
                        <CustomCell
                          required
                          title={t('OrganizationUserManagement.department')}
                          content={
                            <Field
                              component={SelectListboxFormik}
                              options={deptListOptions}
                              selectedOption={selectedDept}
                              value="deptcode"
                              name="deptcode"
                              optionsHeight={12}
                              width={'99%'}
                              onChange={(value: SelectListValue) => {
                                setSelectedDept(value);
                                formik.setFieldValue(`deptcode`, value.id);
                                formik.setFieldValue(`deptname`, value.name);
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.position')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`position`}
                              value={`position`}
                              handleClear={() => {
                                formik.setFieldValue(`position`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.duty')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`duty`}
                              value={`duty`}
                              handleClear={() => {
                                formik.setFieldValue(`duty`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.grade')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`grade`}
                              value={`grade`}
                              handleClear={() => {
                                formik.setFieldValue(`grade`, '');
                              }}
                            />
                          }
                        />
                        {/* <CustomCell
                          title="생일"
                          isDate
                          content={
                            <div className="h-9">
                              <Field
                                component={InputDatePickerV2}
                                pickedDate={birthDate ?? null}
                                setPickedDate={(date: Date | null) => {
                                  if (date === null) {
                                    return;
                                  } else {
                                    const birthDay = dateToBirthDayString(date);
                                    formik.setFieldValue('birthday', birthDay);
                                    setBirthDate(new Date(date));
                                  }
                                }}
                              />
                            </div>
                          }
                        />
                        <CustomCell
                          title="생일구분"
                          content={
                            <div className="w-full h-9 flex gap-6">
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="birthtype"
                                  id={'양력'}
                                  value={formik.values.birthtype}
                                  checked={formik.values.birthtype === '양력'}
                                  onChange={() => {
                                    formik.setFieldValue('birthtype', '양력');
                                  }}
                                />
                                <label htmlFor={'양력'} className="truncate">
                                  양력
                                </label>
                              </div>
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="birthtype"
                                  id={'음력'}
                                  value={formik.values.birthtype}
                                  checked={formik.values.birthtype === '음력'}
                                  onChange={() => {
                                    formik.setFieldValue('birthtype', '음력');
                                  }}
                                />
                                <label htmlFor={'음력'} className="truncate">
                                  음력
                                </label>
                              </div>
                            </div>
                          }
                        /> */}
                        <CustomCell
                          title={t('OrganizationUserManagement.mobilePhone')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`telmobile`}
                              value={`telmobile`}
                              handleClear={() => {
                                formik.setFieldValue(`telmobile`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.telOffice')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`teloffice`}
                              value={`teloffice`}
                              handleClear={() => {
                                formik.setFieldValue(`teloffice`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.extensionNo')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`telextension`}
                              value={`telextension`}
                              handleClear={() => {
                                formik.setFieldValue(`telextension`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title={t('OrganizationUserManagement.email')}
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`email`}
                              value={`email`}
                              handleClear={() => {
                                formik.setFieldValue(`email`, '');
                              }}
                            />
                          }
                        />

                        {/* 신분(학생/학부모/선생) */}
                        {userType === 'edu' && (
                          <CustomCell
                            title={t('OrganizationUserManagement.userField5')}
                            content={
                              <div className="w-full h-9 flex gap-6 mb-1">
                                <div className="gap-3 jcac">
                                  <Field
                                    component={InputRadioCheckbox}
                                    kind="radio"
                                    name="userField5"
                                    id="userFieldTeacher"
                                    value={`t`}
                                    checked={formik.values.userfield5 === 't'}
                                    onChange={() => {
                                      formik.setFieldValue('userfield5', 't');
                                    }}
                                  />
                                  <label
                                    htmlFor="userFieldTeacher"
                                    className="truncate"
                                  >
                                    {t('OrganizationUserManagement.teacher')}
                                  </label>
                                </div>
                                <div className="gap-3 jcac">
                                  <Field
                                    component={InputRadioCheckbox}
                                    kind="radio"
                                    name="userField5"
                                    id="userFieldStudent"
                                    value={`s`}
                                    checked={formik.values.userfield5 === 's'}
                                    onChange={() => {
                                      formik.setFieldValue('userfield5', 's');
                                    }}
                                  />
                                  <label
                                    htmlFor="userFieldStudent"
                                    className="truncate"
                                  >
                                    {t('OrganizationUserManagement.student')}
                                  </label>
                                </div>
                                <div className="gap-3 jcac">
                                  <Field
                                    component={InputRadioCheckbox}
                                    kind="radio"
                                    name="userField5"
                                    id="userFieldParent"
                                    value={`p`}
                                    checked={formik.values.userfield5 === 'p'}
                                    onChange={() => {
                                      formik.setFieldValue('userfield5', 'p');
                                    }}
                                  />
                                  <label
                                    htmlFor="userFieldParent"
                                    className="truncate"
                                  >
                                    {t('OrganizationUserManagement.parent')}
                                  </label>
                                </div>
                              </div>
                            }
                          />
                        )}

                        {/* 자원 관리자 지정 */}
                        <CustomCell
                          title={t(
                            'OrganizationUserManagement.isreservationoperator',
                          )}
                          content={
                            <div className="w-full h-9 flex gap-6">
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isreservationoperator"
                                  id="isreservationoperatorTrue"
                                  value={1}
                                  checked={
                                    formik.values.isreservationoperator === 1
                                  }
                                  onChange={() => {
                                    formik.setFieldValue(
                                      'isreservationoperator',
                                      1,
                                    );
                                  }}
                                />
                                <label
                                  htmlFor="isreservationoperatorTrue"
                                  className="truncate"
                                >
                                  {t('OrganizationUserManagement.enable')}
                                </label>
                              </div>
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isreservationoperator"
                                  id="isreservationoperatorFalse"
                                  value={0}
                                  checked={
                                    formik.values.isreservationoperator === 0
                                  }
                                  onChange={() => {
                                    formik.setFieldValue(
                                      'isreservationoperator',
                                      0,
                                    );
                                  }}
                                />
                                <label
                                  htmlFor="isreservationoperatorFalse"
                                  className="truncate"
                                >
                                  {t('OrganizationUserManagement.disable')}
                                </label>
                              </div>
                            </div>
                          }
                        />

                        {/* 프로젝트 관리자 지정 */}
                        <CustomCell
                          title={t('OrganizationUserManagement.projectAdmin')}
                          content={
                            <div className="w-full h-9 flex gap-6">
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isprojectadmin"
                                  id="isProjectAdminTrue"
                                  value={1}
                                  checked={formik.values.isprojectadmin === 1}
                                  onChange={() => {
                                    formik.setFieldValue('isprojectadmin', 1);
                                  }}
                                />
                                <label
                                  htmlFor="isProjectAdminTrue"
                                  className="truncate"
                                >
                                  {t('OrganizationUserManagement.enable')}
                                </label>
                              </div>
                              <div className="gap-3 jcac">
                                <Field
                                  component={InputRadioCheckbox}
                                  kind="radio"
                                  name="isprojectadmin"
                                  id="isProjectAdminFalse"
                                  value={0}
                                  checked={formik.values.isprojectadmin === 0}
                                  onChange={() => {
                                    formik.setFieldValue('isprojectadmin', 0);
                                  }}
                                />
                                <label
                                  htmlFor="isProjectAdminFalse"
                                  className="truncate"
                                >
                                  {t('OrganizationUserManagement.disable')}
                                </label>
                              </div>
                            </div>
                          }
                        />
                        {/* <CustomCell
                          title="사진위치"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`picrul`}
                              value={`picrul`}
                              handleClear={() => {
                                formik.setFieldValue(`picrul`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드1"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield1`}
                              value={`userfield1`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield1`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드2"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield2`}
                              value={`userfield2`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield2`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드3"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield3`}
                              value={`userfield3`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield3`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드4"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield4`}
                              value={`userfield4`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield4`, '');
                              }}
                            />
                          }
                        />
                        <CustomCell
                          title="사용자 필드5"
                          content={
                            <Field
                              component={InputTextFormik}
                              name={`userfield5`}
                              value={`userfield5`}
                              handleClear={() => {
                                formik.setFieldValue(`userfield5`, '');
                              }}
                            />
                          }
                        /> */}
                      </div>
                      {/* 취소 / 저장 버튼 */}
                      <div className="min-h-[6rem] w-full  jcac">
                        <div className="flex gap-5">
                          <Button
                            onClick={onClickClose}
                            kind="cancel"
                            label={t('OrganizationUserManagement.cancel')}
                          />
                          <Button
                            onClick={() => {
                              if (formik.values.usernameEn === '') {
                                formik.setFieldValue(
                                  'usernameEn',
                                  formik.values.usernameKo,
                                );
                              }
                              formik.handleSubmit();
                            }}
                            kind="primary"
                            label={t('OrganizationUserManagement.save')}
                          />
                        </div>
                      </div>
                      <AutoSubmitToken onClickClose={onClickClose} />
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

const AutoSubmitToken = ({ onClickClose }: { onClickClose: () => void }) => {
  const { userAddStatus } = useAppSelector(
    (state) => state.organizationUserManagement.userInfo,
  );
  // Grab values and submitForm from context
  const event = useFormikContext<IUserFormData>();

  useEffect(() => {
    if (userAddStatus === 'idle') return;
    if (userAddStatus === 'fail') {
      toast.error(String(t('OrganizationUserManagement.toastFail')));
      return;
    }
    if (userAddStatus === 'success') {
      toast.success(String(t('OrganizationUserManagement.toastModifySuccess')));
      // console.log('Token render');
      onClickClose();
    }
  }, [userAddStatus]);

  return null;
};

export default UserOrgAddUserModal;
