import {
  IAdminUserAddApiBody,
  IAdminUserAddApiBodyV2,
  IAdminUserAddApiUserList,
  IadminUserListApiResponse,
  IadminUserModifyApiBodyForOrgDragNDrop,
  IUserFormData,
} from './../../interfaces/api/I-api-admin-users';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  adminUserAddApi,
  adminUserModifyApi,
  adminUserModifyApiV2,
  adminUserModifyApiForOrgDragNDrop,
  adminUserRemoveApi,
} from '../../api/admin/api-admin-user';
import {
  adminOrgAddApi,
  adminOrgImportApi,
  adminOrgModifyApi,
  adminOrgRemoveApi,
} from '../../api/admin/org/api-admin-org';
import { IadminUserModifyApiUserList } from '../../interfaces/api/I-api-admin-users';
import { IhrsOrgDeptFull, IhrsOrgUserAll } from '../../interfaces/api/I-api-hrs-orgs';
import { IhrsUserListApiResponseFull, IhrsUsersSearchApiResponse_FULL } from '../../interfaces/api/I-api-hrs-users';
import {
  IAdminOrgAddApiBody,
  IAdminOrgModifyApiBody,
  IAdminOrgRemoveApiBody,
  IAdminOrgsAddApiBody,
} from '../../interfaces/api/org/i-api-admin-org';
import { DeptNameAndCode } from '../../interfaces/mainPage/I-orgUserMng';
import axios from 'axios';

interface InitialState {
  orgInfo: {
    inputValue: string;
    selectedUser: string;
    contextDeptInfo: IhrsOrgDeptFull | null;
    contextUserInfo: IhrsOrgUserAll | null;
    keyToScroll: string | null;
    keyToExpand: string | null;
    deptStatus: 'idle' | 'pending' | 'success' | 'fail';
    removeDeptStatus: 'idle' | 'pending' | 'success' | 'fail';
    removedDepParent: string | null;
    addDeptStatus: 'idle' | 'pending' | 'success' | 'fail';
    // 사용자 추가 모달 state
    deptNameAndCode: DeptNameAndCode[];
  };
  userInfo: {
    selectedUser: IadminUserListApiResponse | null;
    selectedUsers: IadminUserListApiResponse[] | null;
    isAdding: boolean;
    userAddStatus: 'idle' | 'pending' | 'success' | 'fail';
    userModifyStatus: 'idle' | 'pending' | 'success' | 'fail';
    userRemoveStatus: 'idle' | 'pending' | 'success' | 'fail';
  };
}

const initialState: InitialState = {
  orgInfo: {
    inputValue: '',
    selectedUser: '',
    contextDeptInfo: null,
    contextUserInfo: null,
    keyToScroll: null,
    keyToExpand: null,
    deptStatus: 'idle',
    removeDeptStatus: 'idle',
    removedDepParent: null,
    addDeptStatus: 'idle',
    deptNameAndCode: [],
  },
  userInfo: {
    selectedUser: null,
    selectedUsers: null,
    isAdding: false,
    userAddStatus: 'idle',
    userModifyStatus: 'idle',
    userRemoveStatus: 'idle',
  },
};

// DEPT
// add
export const addDept = createAsyncThunk(
  'orgUser/dept/add',
  async (body: IAdminOrgAddApiBody | IAdminOrgsAddApiBody, { rejectWithValue }) => {
    try {
      const response = await adminOrgAddApi(body);
      if (!response) {
        return alert('check organizationUserManagementSlice addDept');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// DEPT
// Import
export const addDepts = createAsyncThunk(
  'orgUser/dept/add',
  async (body: IAdminOrgAddApiBody | IAdminOrgsAddApiBody, { rejectWithValue }) => {
    try {
      console.log(JSON.stringify(body));
      const response = await adminOrgImportApi(body);
      if (!response) {
        return alert('check organizationUserManagementSlice addDept');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// remove
export const removeDept = createAsyncThunk(
  'orgUser/dept/remove',
  async (body: IAdminOrgRemoveApiBody, { rejectWithValue, dispatch }) => {
    try {
      const response = await adminOrgRemoveApi(body);
      if (!response) return alert('check organizationUserManagementSlice removeDept');

      const removedDeptParent = body.deptpath.split(':').slice(0, -1).join(':');

      if (removedDeptParent) {
        dispatch(setRemovedDepParent(removedDeptParent));
      }

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// modify
export const modifyDept = createAsyncThunk(
  'orgUser/dept/modify',
  async (body: IAdminOrgModifyApiBody, { rejectWithValue }) => {
    try {
      const response = await adminOrgModifyApi(body);
      if (!response) return alert('check organizationUserManagementSlice modifyDept');

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);
// modify array 조직도에서 드래그앤 드랍으로 부서 이동시 사용.
interface Imodifydepts {
  depts: IAdminOrgModifyApiBody[];
  keyToScroll: string;
  keyToExpand: string;
}
export const modifyDepts = createAsyncThunk(
  'orgUser/depts/modify',
  async ({ depts, keyToScroll, keyToExpand }: Imodifydepts, thunk) => {
    console.log(depts);
    try {
      for (const dept of depts) {
        // const response = await adminOrgModifyApi(dept);
        const response = await adminOrgModifyApi({
          ...dept, // 기존 dept 내용 유지
          language: 'ko', // language를 ko로 추가
        });
        if (!response) return alert('check organizationUserManagementSlice modifyDept');
      }

      // return thunk.fulfillWithValue(props.keyToScroll);
      return {
        keyToScroll,
        keyToExpand,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return thunk.rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return thunk.rejectWithValue('unexpected error');
      }
    }
  },
);

// USER
// add
export const addUser = createAsyncThunk('orgUser/user/add', async (body: IAdminUserAddApiBody, { rejectWithValue }) => {
  try {
    console.log(body);
    const response = await adminUserAddApi(body);
    console.log(response);

    if (!response) return alert('check organizationUserManagementSlice addUser');

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return rejectWithValue(error.message);
    } else {
      console.log('unexpected error: ', error);
      return rejectWithValue('unexpected error');
    }
  }
});

// remove
export const removeUser = createAsyncThunk('orgUser/user/remove', async (body: any, { rejectWithValue }) => {
  try {
    console.log('remove!');
    console.log(body);
    const response = await adminUserRemoveApi(body);
    if (!response) return alert('check organizationUserManagementSlice removeUser');

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error: ', error);
      return rejectWithValue(error.message);
    } else {
      console.log('unexpected error: ', error);
      return rejectWithValue('unexpected error');
    }
  }
});

// modify
export const modifyUser = createAsyncThunk(
  'orgUser/user/modify',
  async (body: IAdminUserAddApiBody, { rejectWithValue }) => {
    try {
      console.log(body);
      const response = await adminUserModifyApi(body);
      if (!response) return alert('check organizationUserManagementSlice modifyUser');

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

// modify
export const modifyUserV2 = createAsyncThunk(
  'orgUser/user/modify',
  async (body: IAdminUserAddApiBodyV2, { rejectWithValue }) => {
    try {
      const response = await adminUserModifyApiV2(body);
      if (!response) return alert('check organizationUserManagementSlice modifyUser');

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

interface ImodifyUserAndScroll {
  users: IadminUserModifyApiBodyForOrgDragNDrop;
  keyToScroll: string;
  keyToExpand: string;
}
export const modifyUserAndScroll = createAsyncThunk(
  'orgUser/user/modifyAndScroll',
  async ({ users, keyToScroll, keyToExpand }: ImodifyUserAndScroll, { rejectWithValue }) => {
    try {
      console.log(users);
      const response = await adminUserModifyApiForOrgDragNDrop(users);

      if (!response) return alert('check organizationUserManagementSlice modifyUser');

      return { keyToScroll, keyToExpand };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error: ', error);
        return rejectWithValue(error.message);
      } else {
        console.log('unexpected error: ', error);
        return rejectWithValue('unexpected error');
      }
    }
  },
);

export const organizationUserManagementSlice = createSlice({
  name: 'organizationUserManagement',
  initialState,
  reducers: {
    initOrganizationUserManagementSlice: () => initialState,
    // 조직도 정보
    setContextDeptInfo: (state, { payload: deptInfo }: { payload: IhrsOrgDeptFull | null }) => {
      state.orgInfo.contextDeptInfo = deptInfo;
    },
    setContextUserInfo: (state, { payload: userInfo }: { payload: IhrsOrgUserAll | null }) => {
      state.orgInfo.contextUserInfo = userInfo;
    },
    setRemovedDepParent: (state, { payload: removedDepParent }: { payload: string }) => {
      state.orgInfo.removedDepParent = removedDepParent;
    },
    setKeyToScroll: (state, { payload: key }: { payload: string | null }) => {
      state.orgInfo.keyToScroll = key;
    },
    setKeyToExpand: (state, { payload: key }: { payload: string | null }) => {
      state.orgInfo.keyToExpand = key;
    },
    // 사용자 정보
    setSelectedUser: (state, { payload: selectedUser }: { payload: IadminUserListApiResponse }) => {
      state.userInfo.selectedUser = selectedUser;
    },
    // 사용자 정보
    setSelectedUsers: (state, { payload: selectedUsers }: { payload: IadminUserListApiResponse[] }) => {
      state.userInfo.selectedUsers = selectedUsers;
    },
    // 사용자 추가 모달
    setDeptNameAndCode: (state, { payload: deptNameAndCode }: { payload: DeptNameAndCode[] }) => {
      state.orgInfo.deptNameAndCode = deptNameAndCode;
    },
    // 초기화
    initSelectedUser: (state) => {
      state.userInfo.selectedUser = initialState.userInfo.selectedUser;
    },
    initDeptStatus: (state) => {
      state.orgInfo.deptStatus = 'idle';
      state.orgInfo.removeDeptStatus = 'idle';
      state.orgInfo.addDeptStatus = 'idle';
    },
    initUserStatus: (state) => {
      state.userInfo.userAddStatus = initialState.userInfo.userAddStatus;
    },
    initUserAddStatus: (state) => {
      state.userInfo.userAddStatus = initialState.userInfo.userAddStatus;
    },
    initUserModifyStatus: (state) => {
      state.userInfo.userModifyStatus = initialState.userInfo.userModifyStatus;
    },
    initUserRemoveStatus: (state) => {
      state.userInfo.userRemoveStatus = initialState.userInfo.userRemoveStatus;
    },
  },
  extraReducers(builder) {
    builder
      // DEPT
      // add
      .addCase(addDept.pending, (state) => {
        //console.log('dept pending');
        state.orgInfo.addDeptStatus = 'pending';
      })
      .addCase(addDept.fulfilled, (state) => {
        //console.log('dept success');
        state.orgInfo.addDeptStatus = 'success';
      })
      .addCase(addDept.rejected, (state, action) => {
        //console.log('dept fail');
        alert(JSON.stringify(action.payload));
        state.orgInfo.addDeptStatus = 'fail';
      })
      // remove
      .addCase(removeDept.pending, (state) => {
        state.orgInfo.removeDeptStatus = 'pending';
      })
      .addCase(removeDept.fulfilled, (state) => {
        state.orgInfo.removeDeptStatus = 'success';
      })
      .addCase(removeDept.rejected, (state, action) => {
        // console.log(action);
        alert(JSON.stringify(action.payload));
        state.orgInfo.removeDeptStatus = 'fail';
      })
      // modify
      .addCase(modifyDept.pending, (state) => {
        state.orgInfo.deptStatus = 'pending';
      })
      .addCase(modifyDept.fulfilled, (state) => {
        state.orgInfo.deptStatus = 'success';
      })
      .addCase(modifyDept.rejected, (state, action) => {
        // console.log(action);
        alert(JSON.stringify(action.payload));
        state.orgInfo.deptStatus = 'fail';
      })
      // modify depts array
      .addCase(modifyDepts.pending, (state) => {
        state.orgInfo.deptStatus = 'pending';
        state.orgInfo.keyToExpand = null;
        state.orgInfo.keyToScroll = null;
      })
      .addCase(modifyDepts.fulfilled, (state, { payload }) => {
        state.orgInfo.deptStatus = 'success';
        if (payload) {
          state.orgInfo.keyToScroll = payload.keyToScroll;
          state.orgInfo.keyToExpand = payload.keyToExpand;
        }
      })
      .addCase(modifyDepts.rejected, (state, action) => {
        console.log(action);
        alert(JSON.stringify(action.payload));
        state.orgInfo.deptStatus = 'fail';
      })
      // USER
      // add
      .addCase(addUser.pending, (state) => {
        state.userInfo.userAddStatus = 'pending';
      })
      .addCase(addUser.fulfilled, (state) => {
        state.userInfo.userAddStatus = 'success';
      })
      .addCase(addUser.rejected, (state, action) => {
        alert(JSON.stringify(action.payload));
        state.userInfo.userAddStatus = 'fail';
      })
      // modify
      .addCase(modifyUser.pending, (state) => {
        //console.log('modi pending');
        state.userInfo.userModifyStatus = 'pending';
      })
      .addCase(modifyUser.fulfilled, (state) => {
        //console.log('modi success');
        state.userInfo.userModifyStatus = 'success';
      })
      .addCase(modifyUser.rejected, (state, action) => {
        //console.log('modi fail');
        alert(JSON.stringify(action.payload));
        state.userInfo.userModifyStatus = 'fail';
      })
      // remove
      .addCase(removeUser.pending, (state) => {
        //console.log('pending');
        state.userInfo.userRemoveStatus = 'pending';
      })
      .addCase(removeUser.fulfilled, (state) => {
        //console.log('success');
        state.userInfo.userRemoveStatus = 'success';
      })
      .addCase(removeUser.rejected, (state, action) => {
        alert(JSON.stringify(action.payload));
        state.userInfo.userRemoveStatus = 'fail';
      })

      .addCase(modifyUserAndScroll.pending, (state) => {
        state.userInfo.userModifyStatus = 'pending';
      })
      .addCase(modifyUserAndScroll.fulfilled, (state, { payload }) => {
        state.userInfo.userModifyStatus = 'success';
        if (payload) {
          state.orgInfo.keyToExpand = payload.keyToExpand;
          state.orgInfo.keyToScroll = payload.keyToScroll;
        }
      })
      .addCase(modifyUserAndScroll.rejected, (state, action) => {
        console.log(action);
        alert(JSON.stringify(action.payload));
        state.userInfo.userModifyStatus = 'fail';
      });
  },
});

export const {
  initOrganizationUserManagementSlice,
  // 조직도 정보
  setContextDeptInfo,
  setContextUserInfo,
  setRemovedDepParent,
  setKeyToScroll,
  setKeyToExpand,
  // 사용자 정보
  setSelectedUser,
  setSelectedUsers,
  // 사용자 추가 모달
  setDeptNameAndCode,
  // 초기화
  initSelectedUser,
  initUserAddStatus,
  initDeptStatus,
  initUserStatus,
  initUserModifyStatus,
  initUserRemoveStatus,
} = organizationUserManagementSlice.actions;

export default organizationUserManagementSlice.reducer;
