import { IsidebarListsData } from '../../interfaces/mainPage/I-sidebar';

export const sidebarListsData: IsidebarListsData[] = [
  {
    text: '서비스 모니터링',
    link: '/service-mornitoring',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <g transform="translate(75.795 -331.354)">
            <g
              data-name="사각형 1962"
              transform="translate(-74.795 334.354)"
              style={{ stroke: '#555', strokeWidth: '2px', fill: 'none' }}
            >
              <rect width="16" height="11" rx="1" style={{ stroke: 'none' }} />
              <path style={{ fill: 'none' }} d="M1 1h14v9H1z" />
            </g>
            <rect
              data-name="사각형 1963"
              width="8"
              height="5"
              rx="1"
              transform="translate(-70.795 343.354)"
              style={{ fill: 'currentcolor' }}
            />
            <rect
              data-name="사각형 1964"
              width="10"
              height="1"
              rx=".5"
              transform="translate(-71.795 347.354)"
              style={{ fill: 'currentcolor' }}
            />
          </g>
        </svg>
      );
    },
  },

  // newMenuData.unshift({
  //   text: '서비스 모니터링',
  //   link: '/service-mornitoring',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <g transform="translate(75.795 -331.354)">
  //           <g
  //             data-name="사각형 1962"
  //             transform="translate(-74.795 334.354)"
  //             style={{ stroke: '#555', strokeWidth: '2px', fill: 'none' }}
  //           >
  //             <rect
  //               width="16"
  //               height="11"
  //               rx="1"
  //               style={{ stroke: 'none' }}
  //             />
  //             <path style={{ fill: 'none' }} d="M1 1h14v9H1z" />
  //           </g>
  //           <rect
  //             data-name="사각형 1963"
  //             width="8"
  //             height="5"
  //             rx="1"
  //             transform="translate(-70.795 343.354)"
  //             style={{ fill: 'currentcolor' }}
  //           />
  //           <rect
  //             data-name="사각형 1964"
  //             width="10"
  //             height="1"
  //             rx=".5"
  //             transform="translate(-71.795 347.354)"
  //             style={{ fill: 'currentcolor' }}
  //           />
  //         </g>
  //       </svg>
  //     );
  //   },
  //   menuid: '',
  //   menugid: '',
  //   menutype: 0,
  //   menuurl: '',
  //   menuname: '',
  //   menuorder: 0,
  //   menulevel: 0,
  //   updatetime: '',
  // });

  // {
  //   text: '서비스 모니터링',
  //   link: '/service-mornitoring',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <g transform="translate(75.795 -331.354)">
  //           <g
  //             data-name="사각형 1962"
  //             transform="translate(-74.795 334.354)"
  //             style={{ stroke: '#555', strokeWidth: '2px', fill: 'none' }}
  //           >
  //             <rect
  //               width="16"
  //               height="11"
  //               rx="1"
  //               style={{ stroke: 'none' }}
  //             />
  //             <path style={{ fill: 'none' }} d="M1 1h14v9H1z" />
  //           </g>
  //           <rect
  //             data-name="사각형 1963"
  //             width="8"
  //             height="5"
  //             rx="1"
  //             transform="translate(-70.795 343.354)"
  //             style={{ fill: 'currentcolor' }}
  //           />
  //           <rect
  //             data-name="사각형 1964"
  //             width="10"
  //             height="1"
  //             rx=".5"
  //             transform="translate(-71.795 347.354)"
  //             style={{ fill: 'currentcolor' }}
  //           />
  //         </g>
  //       </svg>
  //     );
  //   },
  // },

  // {
  //   text: '조직 관리',
  //   link: '/organization-management',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <path
  //           data-name="사각형 1254"
  //           style={{ fill: 'none' }}
  //           d="M0 0h18v18H0z"
  //         />
  //         <path
  //           data-name="패스 1153"
  //           d="M231.2 129.584h-.925a4.31 4.31 0 0 0-1.044-1.978 3.169 3.169 0 0 0-2.341-.93h-1.509v-1.455h.727a1.456 1.456 0 0 0 1.454-1.454v-2.909a1.456 1.456 0 0 0-1.454-1.454H223.2a1.456 1.456 0 0 0-1.454 1.454v2.909a1.456 1.456 0 0 0 1.454 1.454h.727v1.455h-1.455v.007a3.32 3.32 0 0 0-2.389.966 4.3 4.3 0 0 0-1.041 1.936h-.932a1.456 1.456 0 0 0-1.454 1.454v2.909a1.456 1.456 0 0 0 1.454 1.454h2.909a1.456 1.456 0 0 0 1.454-1.454v-2.909a1.456 1.456 0 0 0-1.454-1.454h-.45a2.527 2.527 0 0 1 .553-.916 1.913 1.913 0 0 1 1.43-.537h4.339a1.746 1.746 0 0 1 1.3.5 2.555 2.555 0 0 1 .561.958h-.465a1.456 1.456 0 0 0-1.454 1.454v2.909a1.456 1.456 0 0 0 1.454 1.454h2.913a1.456 1.456 0 0 0 1.454-1.454v-2.909a1.456 1.456 0 0 0-1.454-1.46z"
  //           transform="translate(-215.653 -118.404)"
  //           style={{ fill: 'currentcolor' }}
  //         />
  //       </svg>
  //     );
  //   },
  // },
  {
    text: '조직/사용자 관리',
    link: '/organization-user-management',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.001" height="18" viewBox="0 0 18.001 18">
          <g data-name="icon_organization/user_management">
            <path transform="translate(0 .001)" style={{ fill: 'none' }} d="M0 0h18v18H0z" />
            <path
              d="M308.528 51.694a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 4.639a2.455 2.455 0 1 1-2.455 2.455 2.455 2.455 0 0 1 2.455-2.455zm3.068 8.722h-6.131a.489.489 0 0 1-.485-.594 3.826 3.826 0 0 1 7.1 0 .485.485 0 0 1 .011.109.49.49 0 0 1-.491.485z"
              transform="translate(-299.528 -51.694)"
              style={{ fill: 'currentcolor' }}
            />
          </g>
        </svg>
      );
    },
  },
  {
    text: '관리자 관리',
    link: '/admin-id-management',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <g data-name="icon_ admin_ID_management">
            <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
            <path
              data-name="빼기 1"
              d="M-2451 18a1.5 1.5 0 0 1-1.5-1.5V9.771A4.98 4.98 0 0 1-2456 5a5.006 5.006 0 0 1 5-5 5.006 5.006 0 0 1 5 5 4.979 4.979 0 0 1-3.5 4.77v1.73h1a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-1v1h1.5a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-1.5a1.5 1.5 0 0 1-1.5 1.5zm-.75-16a.751.751 0 0 0-.75.751.751.751 0 0 0 .75.75h1.5a.751.751 0 0 0 .75-.75.751.751 0 0 0-.75-.751z"
              transform="translate(2460)"
              style={{ fill: 'currentcolor' }}
            />
          </g>
        </svg>
      );
    },
  },
  {
    text: '학급 관리',
    link: '/class-management',
    svgFile: function () {
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1437_4352)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 18H6V12C6 11.4477 6.44772 11 7 11H11C11.5523 11 12 11.4477 12 12V18H17C17.5523 18 18 17.5523 18 17V9C18 8.44771 17.5523 8 17 8H15V4.53518C15 4.20083 14.8329 3.8886 14.5547 3.70313L9 0L3.4453 3.70313C3.1671 3.8886 3 4.20083 3 4.53518V8H1C0.447715 8 0 8.44772 0 9V17C0 17.5523 0.447716 18 1 18ZM9 8C10.1046 8 11 7.10457 11 6C11 4.89543 10.1046 4 9 4C7.89543 4 7 4.89543 7 6C7 7.10457 7.89543 8 9 8Z"
              fill="#1F62B9"
              style={{ fill: 'currentColor' }}
            />
          </g>
          <defs>
            <clipPath id="clip0_1437_4352">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    },
  },
  {
    text: '상담 채널 관리',
    link: '/counseling-management',
    svgFile: function () {
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 7C4 4.79086 5.79086 3 8 3H10C12.2091 3 14 4.79086 14 7V8V11.989L13.9998 11.9941C13.9992 12.0058 13.9977 12.0298 13.994 12.0645C13.9865 12.1342 13.9704 12.2445 13.9361 12.3816C13.8673 12.657 13.7285 13.0278 13.45 13.3992C12.9276 14.0957 11.756 14.9992 9 14.9992V16.9992C12.244 16.9992 14.0724 15.9027 15.05 14.5992C15.4786 14.0277 15.7128 13.4558 15.841 13H16C17.1046 13 18 12.1046 18 11V9C18 7.89543 17.1046 7 16 7C16 3.68629 13.3137 1 10 1H8C4.68629 1 2 3.68629 2 7C0.895431 7 0 7.89543 0 9V11C0 12.1046 0.895431 13 2 13H4V8V7Z"
            fill="#555555"
            style={{ fill: 'currentColor' }}
          />
        </svg>
      );
    },
  },
  // {
  //   text: '공지사항',
  //   link: '/announcement',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <path
  //           data-name="합치기 3"
  //           d="M18239.555 7444.35c-1.539-.061-2.719-1.072-2.631-2.248a.464.464 0 0 1 .508-.413h4.545c.295 0 .518.188.508.413a1.513 1.513 0 0 1 0 .23 2.571 2.571 0 0 1-2.771 2.021c-.054.001-.105 0-.159-.003zm-5.748-3.551c-.3 0-1.031-.787-.258-1.768.455-.578.957-1.708.957-4.278a5.079 5.079 0 0 1 4.387-4.916v-.706a.79.79 0 0 1 .77-.771s.01-.006.02-.006h.049a.067.067 0 0 1 .033.006h.016a.79.79 0 0 1 .736.771v.706a5.075 5.075 0 0 1 4.389 4.916c0 2.57.5 3.7.953 4.278.775.98.049 1.768-.254 1.768z"
  //           transform="translate(-18230.207 -7427.354)"
  //           style={{
  //             fill: 'currentcolor',
  //             stroke: 'transparent',
  //             strokeMiterlimit: '10',
  //           }}
  //         />
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: '환경파일 업로드',
  //   link: '/upload-environment-file',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <defs>
  //           <clipPath id="w9m8gnpesa">
  //             <path
  //               data-name="사각형 1811"
  //               style={{ fill: 'none' }}
  //               d="M0 0h16v15.999H0z"
  //             />
  //           </clipPath>
  //         </defs>
  //         <g data-name="icon_upload_environment file">
  //           <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //           <g data-name="그룹 2387">
  //             <g
  //               data-name="그룹 2386"
  //               style={{ clipPath: 'url(#w9m8gnpesa)' }}
  //               transform="translate(1 1)"
  //             >
  //               <path
  //                 data-name="패스 1553"
  //                 d="M14.917 4A8.024 8.024 0 0 0 10.365.358 11.6 11.6 0 0 1 11.627 4z"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1554"
  //                 d="M15.532 5.332h-3.695A22.1 22.1 0 0 1 12 8a22.1 22.1 0 0 1-.163 2.667h3.7a7.857 7.857 0 0 0 0-5.334"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1555"
  //                 d="M8 16c.771 0 1.751-1.459 2.283-4H5.717c.532 2.54 1.512 4 2.283 4"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1556"
  //                 d="M5.333 8a21.207 21.207 0 0 0 .167 2.666h5A21.207 21.207 0 0 0 10.667 8a21.207 21.207 0 0 0-.167-2.668h-5A21.207 21.207 0 0 0 5.333 8"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1557"
  //                 d="M4 8a21.9 21.9 0 0 1 .164-2.668H.465a7.881 7.881 0 0 0 0 5.334h3.7A22.072 22.072 0 0 1 4 8"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1558"
  //                 d="M8.009 0h-.018c-.769.011-1.744 1.467-2.274 4h4.566c-.53-2.53-1.5-3.987-2.274-4"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1559"
  //                 d="M5.638.355A8.012 8.012 0 0 0 1.08 4h3.294A11.556 11.556 0 0 1 5.638.355"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1560"
  //                 d="M1.078 12a8.013 8.013 0 0 0 4.56 3.646A11.569 11.569 0 0 1 4.373 12z"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1561"
  //                 d="M10.361 15.645A8.018 8.018 0 0 0 14.922 12h-3.3a11.584 11.584 0 0 1-1.266 3.646"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //             </g>
  //           </g>
  //         </g>
  //       </svg>
  //     );
  //   },
  // },
  {
    text: 'function 버튼 관리',
    link: '/function-button-management',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <defs>
            <clipPath id="gy49tfwj8a">
              <path
                data-name="사각형 1806"
                transform="translate(-.277 -.277)"
                style={{ fill: 'currentcolor' }}
                d="M0 0h16v16H0z"
              />
            </clipPath>
          </defs>
          <g data-name="icon_function_button_management">
            <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
            <g data-name="그룹 2378">
              <g data-name="그룹 2377" style={{ clipPath: 'url(#gy49tfwj8a)' }} transform="translate(1.277 1.277)">
                <path
                  data-name="패스 1544"
                  d="M8.077 2.56C7.028 3.6 6.755 5.831 6.49 7.986c-.306 2.467-.622 5.017-2.125 6.5a5.4 5.4 0 0 1-3.437 1.187.93.93 0 0 1-.8-.459.908.908 0 0 1 0-.917.93.93 0 0 1 .8-.459 3.663 3.663 0 0 0 2.129-.647c1.048-1.041 1.322-3.27 1.587-5.426.306-2.467.622-5.017 2.125-6.5C8.439-.38 11.812.033 12.192.079a.935.935 0 0 1 .739.57.918.918 0 0 1-.13.917.942.942 0 0 1-.868.347c-.724-.11-2.992-.206-3.855.647zM13.375 12 15.5 9.9a.909.909 0 0 0 .185-.857.922.922 0 0 0-.627-.619.936.936 0 0 0-.867.183l-2.129 2.1-2.129-2.1a.936.936 0 0 0-.867-.183.922.922 0 0 0-.627.619.909.909 0 0 0 .185.857l2.125 2.1-2.125 2.109a.91.91 0 0 0-.053 1.345.935.935 0 0 0 1.361-.054l2.129-2.1 2.129 2.1a.936.936 0 0 0 .867.183.922.922 0 0 0 .627-.619.909.909 0 0 0-.185-.857z"
                  style={{ fill: 'currentcolor' }}
                />
              </g>
            </g>
          </g>
        </svg>
      );
    },
  },
  // {
  //   text: 'count 버튼 관리',
  //   link: '/count-button-management',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <g data-name="icon_count_button_management">
  //           <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //           <path
  //             data-name="선 317"
  //             transform="translate(3 9)"
  //             style={{
  //               stroke: 'currentcolor',
  //               strokeLinecap: 'round',
  //               strokeWidth: '2px',
  //               fill: 'none',
  //             }}
  //             d="M0 0h12"
  //           />
  //           <path
  //             data-name="선 318"
  //             transform="rotate(90 3 6)"
  //             style={{
  //               stroke: 'currentcolor',
  //               strokeLinecap: 'round',
  //               strokeWidth: '2px',
  //               fill: 'none',
  //             }}
  //             d="M0 0h12"
  //           />
  //         </g>
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: 'rule/function 관리',
  //   link: '/rule-function-management',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <g data-name="icon_rule/function_management">
  //           <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //           <path
  //             d="M717.014 49.346v-2.08a.442.442 0 0 0-.442-.442h-1.2a.447.447 0 0 1-.429-.339 7.078 7.078 0 0 0-.743-1.785.447.447 0 0 1 .063-.546l.851-.851a.443.443 0 0 0 0-.626l-1.472-1.472a.443.443 0 0 0-.625 0l-.863.863a.448.448 0 0 1-.543.065 7.075 7.075 0 0 0-1.772-.726.447.447 0 0 1-.342-.43v-1.229a.442.442 0 0 0-.442-.442h-2.081a.442.442 0 0 0-.443.442V41a.446.446 0 0 1-.337.429 7.094 7.094 0 0 0-1.744.733.447.447 0 0 1-.546-.063l-.892-.893a.443.443 0 0 0-.626 0l-1.471 1.472a.443.443 0 0 0 0 .626l.9.9a.447.447 0 0 1 .065.543 7.072 7.072 0 0 0-.717 1.735.447.447 0 0 1-.43.339h-1.278a.442.442 0 0 0-.443.442v2.081a.442.442 0 0 0 .443.442h1.276a.447.447 0 0 1 .43.34 7.073 7.073 0 0 0 .716 1.738.447.447 0 0 1-.065.542l-.9.9a.442.442 0 0 0 0 .626l1.471 1.472a.443.443 0 0 0 .626 0l.887-.888a.447.447 0 0 1 .546-.063 7.079 7.079 0 0 0 1.749.735.447.447 0 0 1 .337.429v1.244a.442.442 0 0 0 .443.442h2.081a.442.442 0 0 0 .442-.442v-1.218a.447.447 0 0 1 .342-.43 7.08 7.08 0 0 0 1.777-.729.447.447 0 0 1 .543.065l.858.858a.443.443 0 0 0 .625 0l1.472-1.472a.442.442 0 0 0 0-.626l-.848-.848a.447.447 0 0 1-.063-.545 7.08 7.08 0 0 0 .744-1.787.447.447 0 0 1 .429-.34h1.195a.442.442 0 0 0 .446-.443zm-8.959 2.354a3.392 3.392 0 1 1 3.392-3.392 3.392 3.392 0 0 1-3.392 3.392z"
  //             transform="translate(-699.014 -39.306)"
  //             style={{ fill: 'currentcolor' }}
  //           />
  //         </g>
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: 'rule 지정',
  //   link: '/rule-designation',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <defs>
  //           <clipPath id="8clz6f94ba">
  //             <path
  //               data-name="사각형 1817"
  //               transform="translate(0 -.25)"
  //               style={{ fill: 'none' }}
  //               d="M0 0h18v14H0z"
  //             />
  //           </clipPath>
  //         </defs>
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <g data-name="그룹 2394">
  //           <g
  //             data-name="그룹 2393"
  //             transform="translate(0 2.249)"
  //             style={{ clipPath: 'url(#8clz6f94ba)' }}
  //           >
  //             <path
  //               data-name="패스 1571"
  //               d="m1.814 11.9-.159.015A1.763 1.763 0 0 1 0 10.2v-.5h10.351v1.09a2.4 2.4 0 0 0 .274 1.107z"
  //               transform="translate(0 1.527)"
  //               style={{ fill: 'currentcolor' }}
  //             />
  //             <path
  //               data-name="패스 1572"
  //               d="M15.8 2.411h-2.323V1.158a1.163 1.163 0 0 1 2.326 0z"
  //               transform="translate(2.197 -.001)"
  //               style={{ fill: 'currentcolor' }}
  //             />
  //             <path
  //               data-name="패스 1573"
  //               d="M5.105.024a1.849 1.849 0 0 0-1.279.5A1.676 1.676 0 0 0 3.3 1.749v8.086h7.3a.709.709 0 0 1 .721.695v1.807a1.166 1.166 0 0 0 2.332 0L13.661.929a2.359 2.359 0 0 1 .273-.905zm5.682 7.728H6.135a.81.81 0 1 1 0-1.621h4.652a.81.81 0 1 1 0 1.621m0-3.473H6.135a.81.81 0 1 1 0-1.621h4.652a.81.81 0 1 1 0 1.621"
  //               transform="translate(.538 .003)"
  //               style={{ fill: 'currentcolor' }}
  //             />
  //           </g>
  //         </g>
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: 'DB 관리',
  //   link: '/db-management',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <text
  //           transform="translate(0 14)"
  //           style={{
  //             fill: 'currentcolor',
  //             fontSize: '13px',
  //             fontFamily: 'MalgunGothicBold,Malgun Gothic',
  //             letterSpacing: '-.05em',
  //           }}
  //         >
  //           <tspan x="0" y="0">
  //             DB
  //           </tspan>
  //         </text>
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: 'SQL 관리',
  //   link: '/sql-management',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <defs>
  //           <clipPath id="q0t5hxkska">
  //             <path
  //               data-name="사각형 1809"
  //               style={{ fill: 'currentcolor' }}
  //               d="M0 0h12v18H0z"
  //             />
  //           </clipPath>
  //         </defs>
  //         <path
  //           transform="translate(0 .001)"
  //           style={{ fill: 'none' }}
  //           d="M0 0h18v18H0z"
  //         />
  //         <g data-name="그룹 2382">
  //           <g
  //             data-name="그룹 2381"
  //             style={{ clipPath: 'url(#q0t5hxkska)' }}
  //             transform="translate(2.999)"
  //           >
  //             <g data-name="그룹 2383">
  //               <path
  //                 data-name="패스 1546"
  //                 d="M.01 51.6c.4.173.775.364 1.169.5a11.853 11.853 0 0 0 2.9.572 20.246 20.246 0 0 0 3.835 0 10.862 10.862 0 0 0 3.508-.806c.185-.084.361-.191.57-.3v.234c0 .88-.011 1.76.006 2.639a.657.657 0 0 1-.346.662 8.25 8.25 0 0 1-1.219.543 13.75 13.75 0 0 1-3.788.559A18.456 18.456 0 0 1 3 55.982a8.526 8.526 0 0 1-2.26-.649c-.1-.045-.187-.1-.28-.152A.817.817 0 0 1 0 54.36c.031-.86.009-1.722.009-2.584V51.6"
  //                 transform="translate(0 -48.095)"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1547"
  //                 d="M6.154.008a15.236 15.236 0 0 1 4.292.444 8.232 8.232 0 0 1 1.148.438A1.317 1.317 0 0 1 12 1.2a.448.448 0 0 1 0 .667 2.375 2.375 0 0 1-.591.414 7.272 7.272 0 0 1-2.073.581 20.749 20.749 0 0 1-4.861.177 11.561 11.561 0 0 1-3.231-.612 3.325 3.325 0 0 1-.778-.408c-.415-.3-.406-.663 0-.976A3.39 3.39 0 0 1 1.607.518 13.907 13.907 0 0 1 4.858.011c.431-.023.864 0 1.3 0"
  //                 transform="translate(-.148)"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1548"
  //                 d="M.01 134.6c.4.173.775.364 1.169.5a11.854 11.854 0 0 0 2.9.572 20.245 20.245 0 0 0 3.835 0 10.862 10.862 0 0 0 3.508-.806c.185-.084.361-.191.57-.3v.233c0 .88-.011 1.76.006 2.639a.657.657 0 0 1-.346.662 8.248 8.248 0 0 1-1.219.543 13.75 13.75 0 0 1-3.788.559 18.456 18.456 0 0 1-3.645-.22 8.526 8.526 0 0 1-2.255-.649c-.1-.045-.187-.1-.28-.152A.817.817 0 0 1 0 137.36c.031-.86.009-1.722.009-2.584v-.176"
  //                 transform="translate(0 -126.082)"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1549"
  //                 d="M.01 214.6c.4.173.775.364 1.169.5a11.853 11.853 0 0 0 2.9.572 20.247 20.247 0 0 0 3.835 0 10.861 10.861 0 0 0 3.508-.806c.185-.084.361-.191.57-.3v.233c0 .88-.011 1.76.006 2.639a.657.657 0 0 1-.346.662 8.251 8.251 0 0 1-1.219.543 13.75 13.75 0 0 1-3.788.559 18.455 18.455 0 0 1-3.645-.22 8.525 8.525 0 0 1-2.255-.649c-.1-.045-.187-.1-.28-.152A.817.817 0 0 1 0 217.36c.031-.86.009-1.722.009-2.584v-.176"
  //                 transform="translate(0 -201.216)"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //             </g>
  //           </g>
  //         </g>
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: '실시간 통계',
  //   link: '/real-time-statistics',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <path
  //           data-name="Icon awesome-chart-pie"
  //           d="M15.523 8.471H8.544l4.648 4.648a.472.472 0 0 0 .653.02A7.044 7.044 0 0 0 16 9a.471.471 0 0 0-.477-.529zm-.466-1.906A7.076 7.076 0 0 0 8.494 0 .473.473 0 0 0 8 .478v6.581h6.581a.473.473 0 0 0 .476-.494zM6.588 8.471V1.492a.471.471 0 0 0-.525-.472A7.054 7.054 0 0 0 0 8.246a7.146 7.146 0 0 0 7.144 6.812 7 7 0 0 0 3.978-1.295.467.467 0 0 0 .046-.708z"
  //           transform="translate(1 1.47)"
  //           style={{ fill: 'currentcolor' }}
  //         />
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: '메신저 사용량 통계',
  //   link: '/messenger-usage-statistics',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <path
  //           data-name="Icon awesome-chart-pie"
  //           d="M15.523 8.471H8.544l4.648 4.648a.472.472 0 0 0 .653.02A7.044 7.044 0 0 0 16 9a.471.471 0 0 0-.477-.529zm-.466-1.906A7.076 7.076 0 0 0 8.494 0 .473.473 0 0 0 8 .478v6.581h6.581a.473.473 0 0 0 .476-.494zM6.588 8.471V1.492a.471.471 0 0 0-.525-.472A7.054 7.054 0 0 0 0 8.246a7.146 7.146 0 0 0 7.144 6.812 7 7 0 0 0 3.978-1.295.467.467 0 0 0 .046-.708z"
  //           transform="translate(1 1.47)"
  //           style={{ fill: 'currentcolor' }}
  //         />
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: '접속 이력',
  //   link: '/connection-history',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <path
  //           d="M416.836 476.708a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm4.8 8.8h-4.8a.8.8 0 0 1-.8-.8v-4.8a.8.8 0 1 1 1.6 0v4h4a.8.8 0 1 1 0 1.6z"
  //           transform="translate(-407.836 -475.708)"
  //           style={{ fill: 'currentcolor' }}
  //         />
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: '로그 뷰어',
  //   link: '/log-viewer',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <defs>
  //           <clipPath id="d421jwg3ia">
  //             <path
  //               data-name="사각형 1816"
  //               style={{ fill: 'currentcolor' }}
  //               d="M0 0h16v17H0z"
  //             />
  //           </clipPath>
  //         </defs>
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <g data-name="그룹 2385">
  //           <g data-name="그룹 2392">
  //             <g
  //               data-name="그룹 2391"
  //               style={{ clipPath: 'url(#d421jwg3ia)' }}
  //               transform="translate(2)"
  //             >
  //               <path
  //                 data-name="패스 1568"
  //                 d="M5.647 10.389a4.715 4.715 0 0 1 4.706-4.722 4.649 4.649 0 0 1 1.617.3V4.455H8.144c-.424 0-.616-.193-.616-.617V0H1.642a5.224 5.224 0 0 0-.766.305 1.77 1.77 0 0 0-.842 1.229c-.008.038-.023.075-.034.111v13.708a3.925 3.925 0 0 0 .157.5 1.83 1.83 0 0 0 1.756 1.14c1.46.01 1.551 0 3.011 0h5.065a1.887 1.887 0 0 0 1.988-1.987v-.2a4.666 4.666 0 0 1-1.624.306 4.715 4.715 0 0 1-4.706-4.722"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1569"
  //                 d="M9.1.4v3.043h3.042L9.1.4"
  //                 transform="translate(-.535 -.022)"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //               <path
  //                 data-name="패스 1570"
  //                 d="M16.149 15.4c-.548-.555-1.1-1.106-1.652-1.657l-.695-.7c-.047-.047-.092-.1-.136-.144a3.372 3.372 0 0 0-.661-4.564A3.341 3.341 0 0 0 8.1 12.731a3.24 3.24 0 0 0 1.8 1.412 3.342 3.342 0 0 0 3-.467 1.505 1.505 0 0 0 .128.17l.79.792 1.636 1.641a.586.586 0 0 0 .571.183.62.62 0 0 0 .421-.406V15.8a3.5 3.5 0 0 0-.3-.394"
  //                 transform="translate(-.447 -.423)"
  //                 style={{ fill: 'currentcolor' }}
  //               />
  //             </g>
  //           </g>
  //         </g>
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: '관리자 로그',
  //   link: '/admin-log',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
  //         <path
  //           d="M260.292 503.444h-12.25a.882.882 0 0 0-.875.889v14.222a.881.881 0 0 0 .875.889h12.25a.882.882 0 0 0 .875-.889v-14.222a.882.882 0 0 0-.875-.889zm-6.125 12.444h-3.5a.889.889 0 0 1 0-1.778h3.5a.889.889 0 0 1 0 1.778zm3.5-3.556h-7a.889.889 0 0 1 0-1.778h7a.889.889 0 0 1 0 1.778zm0-3.556h-7a.889.889 0 0 1 0-1.778h7a.889.889 0 0 1 0 1.778z"
  //           transform="translate(-245.168 -502.444)"
  //           style={{ fill: 'currentcolor' }}
  //         />
  //       </svg>
  //     );
  //   },
  // },
  // {
  //   text: '파일전송 이력관리',
  //   link: '/file-transfer-history-management',
  //   svgFile: function () {
  //     return (
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         xmlnsXlink="http://www.w3.org/1999/xlink"
  //         width="18"
  //         height="18"
  //         viewBox="0 0 18 18"
  //       >
  //         <defs>
  //           <clipPath id="clip-path">
  //             <rect
  //               id="사각형_1814"
  //               data-name="사각형 1814"
  //               style={{ fill: 'currentcolor' }}
  //               width="16.001"
  //               height="16"
  //             />
  //           </clipPath>
  //         </defs>
  //         <g
  //           id="icon_file_transfer_history_management"
  //           transform="translate(75.795 -331.354)"
  //         >
  //           <rect
  //             id="container"
  //             style={{ fill: 'none' }}
  //             width="18"
  //             height="18"
  //             transform="translate(-75.795 331.354)"
  //           />
  //           <g id="symbol" transform="translate(-74.796 332.358)">
  //             <g
  //               id="그룹_2388"
  //               data-name="그룹 2388"
  //               style={{ clipPath: 'url(#clip-path)' }}
  //               transform="translate(0 0)"
  //             >
  //               <path
  //                 id="패스_1562"
  //                 data-name="패스 1562"
  //                 style={{ fill: 'currentcolor' }}
  //                 d="M15.913,4.328,12.519.135a.348.348,0,0,0-.155-.106V5h-8V0H1.818A1.866,1.866,0,0,0,0,1.9V14.1A1.867,1.867,0,0,0,1.818,16H14.182A1.865,1.865,0,0,0,16,14.109V4.579a.39.39,0,0,0-.087-.251M13,14.009H3v-6H13Z"
  //                 transform="translate(0 0)"
  //               />
  //               <rect
  //                 id="사각형_1812"
  //                 data-name="사각형 1812"
  //                 style={{ fill: 'currentcolor' }}
  //                 width="4.363"
  //                 height="0.001"
  //                 transform="translate(4.364)"
  //               />
  //               <path
  //                 id="패스_1563"
  //                 data-name="패스 1563"
  //                 style={{ fill: 'currentcolor' }}
  //                 d="M12.242,0a.362.362,0,0,1,.122.028V0h-.122Z"
  //                 transform="translate(0 0)"
  //               />
  //               <rect
  //                 id="사각형_1813"
  //                 data-name="사각형 1813"
  //                 style={{ fill: 'currentcolor' }}
  //                 width="2"
  //                 height="0.001"
  //                 transform="translate(8.727)"
  //               />
  //               <path
  //                 id="패스_1564"
  //                 data-name="패스 1564"
  //                 style={{ fill: 'currentcolor' }}
  //                 d="M8.727,2.7c0,.166.224.3.5.3h1c.276,0,.5-.134.5-.3V0h-2Z"
  //                 transform="translate(0 0)"
  //               />
  //             </g>
  //           </g>
  //         </g>
  //       </svg>
  //     );
  //   },
  // },
  {
    text: '기관 연결 관리',
    link: '/federation-management',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path transform="translate(0 .001)" style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <path
            data-name="패스 1345"
            d="m820.95 233.593 3.6-3.6a.9.9 0 0 1 1.273 1.273l-2.064 2.064h10.828a.9.9 0 0 1 0 1.8h-10.828l2.064 2.064a.9.9 0 1 1-1.273 1.273l-3.6-3.6a.9.9 0 0 1 0-1.274z"
            transform="translate(-818.686 -221.228)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1575"
            d="m831.223 233.593-3.6-3.6a.9.9 0 0 0-1.273 1.273l2.064 2.064h-10.828a.9.9 0 0 0 0 1.8h10.828l-2.064 2.064a.9.9 0 1 0 1.273 1.273l3.6-3.6a.9.9 0 0 0 0-1.274z"
            transform="translate(-814.686 -229.728)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: '테넌트 관리',
    link: 'tenant-management',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <defs>
            <clipPath id="rrpbvsri6a">
              <circle
                data-name="타원 421"
                cx="8"
                cy="8"
                r="8"
                transform="translate(-.492 -.492)"
                style={{ fill: '#fff' }}
              />
            </clipPath>
          </defs>
          <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <g data-name="마스크 그룹 2" style={{ clipPath: 'url(#rrpbvsri6a)' }} transform="translate(1.492 1.492)">
            <g data-name="그룹 2513">
              <path
                data-name="빼기 2"
                d="M21094.537 6326.511h-4.031v-16h16v16h-4.031v-3.041a3.97 3.97 0 1 0-7.939 0v3.041zm3.973-12.751a2.384 2.384 0 1 0 2.383 2.384 2.386 2.386 0 0 0-2.383-2.384z"
                transform="translate(-21091 -6311)"
                style={{ fill: 'currentcolor' }}
              />
            </g>
          </g>
        </svg>
      );
    },
  },
  {
    text: '테넌트 모니터링',
    link: 'tenant-mornitor',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <g transform="translate(75.795 -331.354)">
            <g
              data-name="사각형 1962"
              transform="translate(-74.795 334.354)"
              style={{ stroke: '#555', strokeWidth: '2px', fill: 'none' }}
            >
              <rect width="16" height="11" rx="1" style={{ stroke: 'none' }} />
              <path style={{ fill: 'none' }} d="M1 1h14v9H1z" />
            </g>
            <rect
              data-name="사각형 1963"
              width="8"
              height="5"
              rx="1"
              transform="translate(-70.795 343.354)"
              style={{ fill: 'currentcolor' }}
            />
            <rect
              data-name="사각형 1964"
              width="10"
              height="1"
              rx=".5"
              transform="translate(-71.795 347.354)"
              style={{ fill: 'currentcolor' }}
            />
          </g>
        </svg>
      );
    },
  },
  {
    text: '테넌트 로그 관리',
    link: 'tenant-log-management',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path transform="translate(0 .001)" style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <path
            data-name="패스 1576"
            d="M666.922 559.33v3.223h3.221z"
            transform="translate(-656.852 -558.929)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1577"
            d="M667.042 575.629a1.524 1.524 0 0 1-2-.137l-.809-.81-.041-.039-.022-.024a3.023 3.023 0 0 0-1.41-3.463v-1.185a1.528 1.528 0 0 0 1-2.436 1.507 1.507 0 0 1 .447-1.069l.827-.827.025-.025.014-.012a1.521 1.521 0 0 1 1.963-.106 1.525 1.525 0 0 1 1.507-1.31h1.182a1.516 1.516 0 0 1 1.3.751v-1.267h-4.052c-.449 0-.652-.2-.652-.653v-4.064H660.1a5.456 5.456 0 0 0-.811.323 1.87 1.87 0 0 0-.892 1.3c-.008.04-.024.079-.036.117v14.517a4.21 4.21 0 0 0 .166.53 1.94 1.94 0 0 0 1.86 1.207c1.546.011 1.642 0 3.189 0h5.364a2.035 2.035 0 0 0 .228-.011h-.614a1.525 1.525 0 0 1-1.512-1.307z"
            transform="translate(-657.357 -558.952)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1578"
            d="M673.677 569.361H673a.254.254 0 0 1-.238-.193 4 4 0 0 0-.424-1.013.255.255 0 0 1 .036-.31l.483-.482a.252.252 0 0 0 0-.357l-.832-.832a.252.252 0 0 0-.355 0l-.489.49a.254.254 0 0 1-.178.072.25.25 0 0 1-.13-.036c-.048-.028-.1-.048-.149-.073a4.019 4.019 0 0 0-.858-.339.254.254 0 0 1-.194-.244v-.7a.251.251 0 0 0-.251-.251h-1.182a.252.252 0 0 0-.251.251v.713a.253.253 0 0 1-.191.238 3.991 3.991 0 0 0-.99.416.261.261 0 0 1-.132.037.254.254 0 0 1-.178-.073l-.507-.506a.249.249 0 0 0-.355 0l-.835.831a.251.251 0 0 0 0 .355l.513.513a.254.254 0 0 1 .036.308 4.049 4.049 0 0 0-.407.986.254.254 0 0 1-.244.192h-.727a.252.252 0 0 0-.251.251v1.185a.251.251 0 0 0 .251.25h.724a.255.255 0 0 1 .244.193 4 4 0 0 0 .407.987.253.253 0 0 1-.037.308l-.51.511a.251.251 0 0 0 0 .355l.831.832a.252.252 0 0 0 .179.074.246.246 0 0 0 .176-.072l.5-.5a.255.255 0 0 1 .178-.073.249.249 0 0 1 .132.038 4.075 4.075 0 0 0 .993.417.252.252 0 0 1 .191.237v.706a.251.251 0 0 0 .251.251h1.182a.251.251 0 0 0 .251-.251v-.694a.255.255 0 0 1 .194-.244 3.977 3.977 0 0 0 .865-.342c.048-.025.1-.044.144-.071a.255.255 0 0 1 .308.036l.487.488a.254.254 0 0 0 .357 0l.832-.832a.251.251 0 0 0 0-.355l-.475-.475a.253.253 0 0 1-.036-.309 4.037 4.037 0 0 0 .424-1.013.253.253 0 0 1 .244-.193h.679a.252.252 0 0 0 .24-.253v-1.182a.251.251 0 0 0-.249-.253zm-4.837 2.139a1.254 1.254 0 1 1 .075 0z"
            transform="translate(-657.041 -558.589)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: '관리자 로그 관리',
    link: 'admin-log-management',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path transform="translate(0 .001)" style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <path
            data-name="패스 1576"
            d="M666.922 559.33v3.223h3.221z"
            transform="translate(-656.852 -558.929)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1577"
            d="M667.042 575.629a1.524 1.524 0 0 1-2-.137l-.809-.81-.041-.039-.022-.024a3.023 3.023 0 0 0-1.41-3.463v-1.185a1.528 1.528 0 0 0 1-2.436 1.507 1.507 0 0 1 .447-1.069l.827-.827.025-.025.014-.012a1.521 1.521 0 0 1 1.963-.106 1.525 1.525 0 0 1 1.507-1.31h1.182a1.516 1.516 0 0 1 1.3.751v-1.267h-4.052c-.449 0-.652-.2-.652-.653v-4.064H660.1a5.456 5.456 0 0 0-.811.323 1.87 1.87 0 0 0-.892 1.3c-.008.04-.024.079-.036.117v14.517a4.21 4.21 0 0 0 .166.53 1.94 1.94 0 0 0 1.86 1.207c1.546.011 1.642 0 3.189 0h5.364a2.035 2.035 0 0 0 .228-.011h-.614a1.525 1.525 0 0 1-1.512-1.307z"
            transform="translate(-657.357 -558.952)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1578"
            d="M673.677 569.361H673a.254.254 0 0 1-.238-.193 4 4 0 0 0-.424-1.013.255.255 0 0 1 .036-.31l.483-.482a.252.252 0 0 0 0-.357l-.832-.832a.252.252 0 0 0-.355 0l-.489.49a.254.254 0 0 1-.178.072.25.25 0 0 1-.13-.036c-.048-.028-.1-.048-.149-.073a4.019 4.019 0 0 0-.858-.339.254.254 0 0 1-.194-.244v-.7a.251.251 0 0 0-.251-.251h-1.182a.252.252 0 0 0-.251.251v.713a.253.253 0 0 1-.191.238 3.991 3.991 0 0 0-.99.416.261.261 0 0 1-.132.037.254.254 0 0 1-.178-.073l-.507-.506a.249.249 0 0 0-.355 0l-.835.831a.251.251 0 0 0 0 .355l.513.513a.254.254 0 0 1 .036.308 4.049 4.049 0 0 0-.407.986.254.254 0 0 1-.244.192h-.727a.252.252 0 0 0-.251.251v1.185a.251.251 0 0 0 .251.25h.724a.255.255 0 0 1 .244.193 4 4 0 0 0 .407.987.253.253 0 0 1-.037.308l-.51.511a.251.251 0 0 0 0 .355l.831.832a.252.252 0 0 0 .179.074.246.246 0 0 0 .176-.072l.5-.5a.255.255 0 0 1 .178-.073.249.249 0 0 1 .132.038 4.075 4.075 0 0 0 .993.417.252.252 0 0 1 .191.237v.706a.251.251 0 0 0 .251.251h1.182a.251.251 0 0 0 .251-.251v-.694a.255.255 0 0 1 .194-.244 3.977 3.977 0 0 0 .865-.342c.048-.025.1-.044.144-.071a.255.255 0 0 1 .308.036l.487.488a.254.254 0 0 0 .357 0l.832-.832a.251.251 0 0 0 0-.355l-.475-.475a.253.253 0 0 1-.036-.309 4.037 4.037 0 0 0 .424-1.013.253.253 0 0 1 .244-.193h.679a.252.252 0 0 0 .24-.253v-1.182a.251.251 0 0 0-.249-.253zm-4.837 2.139a1.254 1.254 0 1 1 .075 0z"
            transform="translate(-657.041 -558.589)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: '테넌트 사용 통계',
    link: 'tenant-usage-statistics',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path transform="translate(0 .001)" style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <path
            data-name="패스 1576"
            d="M666.922 559.33v3.223h3.221z"
            transform="translate(-656.852 -558.929)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1577"
            d="M667.042 575.629a1.524 1.524 0 0 1-2-.137l-.809-.81-.041-.039-.022-.024a3.023 3.023 0 0 0-1.41-3.463v-1.185a1.528 1.528 0 0 0 1-2.436 1.507 1.507 0 0 1 .447-1.069l.827-.827.025-.025.014-.012a1.521 1.521 0 0 1 1.963-.106 1.525 1.525 0 0 1 1.507-1.31h1.182a1.516 1.516 0 0 1 1.3.751v-1.267h-4.052c-.449 0-.652-.2-.652-.653v-4.064H660.1a5.456 5.456 0 0 0-.811.323 1.87 1.87 0 0 0-.892 1.3c-.008.04-.024.079-.036.117v14.517a4.21 4.21 0 0 0 .166.53 1.94 1.94 0 0 0 1.86 1.207c1.546.011 1.642 0 3.189 0h5.364a2.035 2.035 0 0 0 .228-.011h-.614a1.525 1.525 0 0 1-1.512-1.307z"
            transform="translate(-657.357 -558.952)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1578"
            d="M673.677 569.361H673a.254.254 0 0 1-.238-.193 4 4 0 0 0-.424-1.013.255.255 0 0 1 .036-.31l.483-.482a.252.252 0 0 0 0-.357l-.832-.832a.252.252 0 0 0-.355 0l-.489.49a.254.254 0 0 1-.178.072.25.25 0 0 1-.13-.036c-.048-.028-.1-.048-.149-.073a4.019 4.019 0 0 0-.858-.339.254.254 0 0 1-.194-.244v-.7a.251.251 0 0 0-.251-.251h-1.182a.252.252 0 0 0-.251.251v.713a.253.253 0 0 1-.191.238 3.991 3.991 0 0 0-.99.416.261.261 0 0 1-.132.037.254.254 0 0 1-.178-.073l-.507-.506a.249.249 0 0 0-.355 0l-.835.831a.251.251 0 0 0 0 .355l.513.513a.254.254 0 0 1 .036.308 4.049 4.049 0 0 0-.407.986.254.254 0 0 1-.244.192h-.727a.252.252 0 0 0-.251.251v1.185a.251.251 0 0 0 .251.25h.724a.255.255 0 0 1 .244.193 4 4 0 0 0 .407.987.253.253 0 0 1-.037.308l-.51.511a.251.251 0 0 0 0 .355l.831.832a.252.252 0 0 0 .179.074.246.246 0 0 0 .176-.072l.5-.5a.255.255 0 0 1 .178-.073.249.249 0 0 1 .132.038 4.075 4.075 0 0 0 .993.417.252.252 0 0 1 .191.237v.706a.251.251 0 0 0 .251.251h1.182a.251.251 0 0 0 .251-.251v-.694a.255.255 0 0 1 .194-.244 3.977 3.977 0 0 0 .865-.342c.048-.025.1-.044.144-.071a.255.255 0 0 1 .308.036l.487.488a.254.254 0 0 0 .357 0l.832-.832a.251.251 0 0 0 0-.355l-.475-.475a.253.253 0 0 1-.036-.309 4.037 4.037 0 0 0 .424-1.013.253.253 0 0 1 .244-.193h.679a.252.252 0 0 0 .24-.253v-1.182a.251.251 0 0 0-.249-.253zm-4.837 2.139a1.254 1.254 0 1 1 .075 0z"
            transform="translate(-657.041 -558.589)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: '업그레이드 관리',
    link: 'upgrade-management',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path transform="translate(2)" style={{ fill: 'none' }} d="M0 0h15.178v18H0z" />
          <path
            data-name="패스 1579"
            d="m-519.758-357.693-.027-.024-.023-.021-1.55-1.822-1.262-1.484-2.266-2.662-1.384-1.626a.338.338 0 0 0-.247-.16c-.109 0-.177.08-.247.164l-6.539 7.673a.272.272 0 0 0-.05.247.218.218 0 0 0 .165.164h.019a.415.415 0 0 0 .093.012h4.099a16.337 16.337 0 0 1-.207 2.841 7.578 7.578 0 0 1-.84 2.479 3.887 3.887 0 0 1-1.479 1.509 3.867 3.867 0 0 1-1.887.475 6.09 6.09 0 0 1-1.86-.3 9.4 9.4 0 0 1-1.939-.873 2.498 2.498 0 0 0-.122-.066l-.052-.028a.188.188 0 0 0-.252.078l-.021.034a.275.275 0 0 0-.036.183.249.249 0 0 0 .086.158l.029.023.022.017a11.256 11.256 0 0 0 2.736 2.121 8.5 8.5 0 0 0 3.189 1.039 7.593 7.593 0 0 0 .818.045 6.064 6.064 0 0 0 1.992-.323 5.977 5.977 0 0 0 2.56-1.713 8.44 8.44 0 0 0 1.749-3.307 14.916 14.916 0 0 0 .529-4.393h3.814c.065 0 .129 0 .19-.009l.08-.005a.228.228 0 0 0 .2-.242v-.023a.259.259 0 0 0-.08-.181z"
            transform="translate(537.68 365.492)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: 'Auto Scale 관리',
    link: 'auto-scale-management',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.001" height="18.001" viewBox="0 0 18.001 18.001">
          <path transform="translate(.001 .001)" style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <path
            data-name="패스 1584"
            d="M359.975-671.029a.907.907 0 0 0 .188-.02l.915-.2v2.163a.342.342 0 0 0 .045.118.307.307 0 0 0 .06.081l1.776 1.568a.363.363 0 0 0 .245.09.362.362 0 0 0 .243-.089l1.78-1.571a.334.334 0 0 0 .064-.088.856.856 0 0 0 .031-.14v-2.131l.915.2a.924.924 0 0 0 .19.02.818.818 0 0 0 .546-.2.64.64 0 0 0 .227-.482.645.645 0 0 0-.227-.484l-3.228-2.851a.831.831 0 0 0-.546-.186.832.832 0 0 0-.547.186l-3.228 2.851a.644.644 0 0 0-.226.483.641.641 0 0 0 .226.483.819.819 0 0 0 .551.199z"
            transform="translate(-354.202 675.231)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1585"
            d="m369.16-667.853-3.16 3.108a.765.765 0 0 0 0 1.094l3.29 3.233a.792.792 0 0 0 .557.226.79.79 0 0 0 .557-.226.764.764 0 0 0 .207-.737l-.232-.915h2.414a.969.969 0 0 0 .976-.959v-2.337a.969.969 0 0 0-.976-.959h-2.415l.233-.916a.763.763 0 0 0-.207-.737.793.793 0 0 0-.557-.213.8.8 0 0 0-.557.213l-.119.117v.495-.369h-.007z"
            transform="translate(-355.767 673.191)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1586"
            d="M357.066-667.978a.793.793 0 0 0-.557-.213.792.792 0 0 0-.556.213.759.759 0 0 0-.207.737l.232.916h-2.415a.968.968 0 0 0-.976.959v2.337a.968.968 0 0 0 .976.959h2.415l-.232.917a.758.758 0 0 0 .207.737.786.786 0 0 0 .556.226.786.786 0 0 0 .557-.226l3.29-3.233a.766.766 0 0 0 0-1.1z"
            transform="translate(-352.587 673.191)"
            style={{ fill: 'currentcolor' }}
          />
          <path
            data-name="패스 1587"
            d="M366.994-658.547a.83.83 0 0 0-.548-.2.869.869 0 0 0-.19.021l-.917.2v-2.09a.939.939 0 0 0-.048-.2.38.38 0 0 0-.054-.066l-1.782-1.569a.372.372 0 0 0-.245-.088.373.373 0 0 0-.246.088l-1.784 1.57a.338.338 0 0 0-.058.083.282.282 0 0 0-.041.132v2.14l-.916-.2a.868.868 0 0 0-.19-.021.818.818 0 0 0-.615.266.682.682 0 0 0 .138.96l3.164 2.784a.82.82 0 0 0 .548.2.827.827 0 0 0 .548-.2l3.234-2.846a.73.73 0 0 0 .128-.149v-.39h.008v.379a.609.609 0 0 0 .092-.323.64.64 0 0 0-.226-.481z"
            transform="translate(-354.22 672.538)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: '로그아웃',
    link: '/logout',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <path
            data-name="패스 1344"
            d="M824.587 242.729h-9a.9.9 0 0 1-.9-.9v-16.2a.9.9 0 0 1 .9-.9h8.588a.9.9 0 1 1 0 1.8h-7.688v14.4h8.1a.9.9 0 0 1 0 1.8z"
            style={{ fill: 'currentcolor' }}
            transform="translate(-813.687 -224.729)"
          />
          <path
            data-name="패스 1345"
            d="m831.223 233.593-3.6-3.6a.9.9 0 0 0-1.273 1.273l2.064 2.064h-6.828a.9.9 0 0 0 0 1.8h6.828l-2.064 2.064a.9.9 0 1 0 1.273 1.273l3.6-3.6a.9.9 0 0 0 0-1.274z"
            transform="translate(-814.487 -225.229)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
  {
    text: '테스트',
    link: '/test-page',
    svgFile: function () {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path style={{ fill: 'none' }} d="M0 0h18v18H0z" />
          <path
            data-name="패스 1344"
            d="M824.587 242.729h-9a.9.9 0 0 1-.9-.9v-16.2a.9.9 0 0 1 .9-.9h8.588a.9.9 0 1 1 0 1.8h-7.688v14.4h8.1a.9.9 0 0 1 0 1.8z"
            style={{ fill: 'currentcolor' }}
            transform="translate(-813.687 -224.729)"
          />
          <path
            data-name="패스 1345"
            d="m831.223 233.593-3.6-3.6a.9.9 0 0 0-1.273 1.273l2.064 2.064h-6.828a.9.9 0 0 0 0 1.8h6.828l-2.064 2.064a.9.9 0 1 0 1.273 1.273l3.6-3.6a.9.9 0 0 0 0-1.274z"
            transform="translate(-814.487 -225.229)"
            style={{ fill: 'currentcolor' }}
          />
        </svg>
      );
    },
  },
];
