import { store } from '../redux/store';
import { IUserInfo } from '../interfaces/api/I-api-hrs-users';
import { toast } from 'react-toastify';
import { getOrgCode } from './h-userInfo';
import { addRole, removeRole } from '../redux/slice/adminIdManagementSlice';
import { IroleListApiListResponse } from '../interfaces/api/I-api-admin-role';
import { COMMON_TENANT_LIST_TABLE_COL } from '../components/tables/common/columns';

const state = store.getState();
const dispatch = store.dispatch;
export const a = '';
// list

// info
// add
export const handleAddUserToRole = async (selectedUsers: IUserInfo[]) => {
  const {
    conf: { confData },
  } = store.getState();
  const orgcode = getOrgCode();
  console.log(confData);
  if (!confData) {
    toast.error('confData값이 null 입니다.');
    return;
  }

  if (!orgcode) {
    toast.error('orgcode값이 null 입니다.');
    return;
  }

  const userlist = selectedUsers.map((user) => ({
    bid: `${user.bid}`,
  }));

  const body = {
    tenantid: confData.tenantid,
    orgcode,
    userlist,
    menugid: confData.menugid,
    rolestatus: 0,
  };

  const result = await dispatch(addRole(body));

  console.log(result);
};
// remove
export const handleRemoveUserFromRole = (selectedUsers: IroleListApiListResponse[]) => {
  console.log(selectedUsers);
  const userlist = selectedUsers.map((user) => ({
    bid: `${user.bid}`,
  }));

  const body = {
    userlist,
  };

  dispatch(removeRole(body));
};

// modify
