import { createSlice } from '@reduxjs/toolkit';
import {
  IcntBtnMngModalTrigger,
  IfederationMngModalTrigger,
  IorgUserMngModalTrigger,
  IorgUserMngReactModalTrigger,
  IruleFuncMngModalTrigger,
} from '../../interfaces/I-toggleSlice';
import { RootState } from '../store';

interface ToggleState {
  sideBar: boolean;
  modals: {
    [key: string]:
      | IorgUserMngModalTrigger
      | IcntBtnMngModalTrigger
      | IruleFuncMngModalTrigger
      | IfederationMngModalTrigger;
    orgUserMng: IorgUserMngModalTrigger;
    funcBtnMng: IcntBtnMngModalTrigger;
    cntBtnMng: IcntBtnMngModalTrigger;
    ruleFuncMng: IruleFuncMngModalTrigger;
    federationMng: IfederationMngModalTrigger;
  };
  reactModals: {
    [key: string]: IorgUserMngReactModalTrigger;
    orgUserMng: IorgUserMngReactModalTrigger;
  };
}

const initialState: ToggleState = {
  sideBar: true,
  modals: {
    orgUserMng: {
      addUser: false,
      modifyUser: false,
      bulkDept: false,
      bulkUser: false,
      profileImg: false,
    },
    funcBtnMng: {
      main: false,
      add: false,
      modify: false,
    },
    cntBtnMng: {
      main: false,
      add: false,
      modify: false,
    },
    ruleFuncMng: {
      func: false,
      rule: false,
      ruleAdd: false,
      ruleModify: false,
      ruleOption: false,
    },
    federationMng: {
      accept: false,
      cancel: false,
      connectApply: false,
      delete: false,
      disConnectApply: false,
      reject: false,
    },
  },
  reactModals: {
    orgUserMng: {
      removeDept: false,
      modifyDept: false,
      addDept: false,
      userList: false,
      bulkDept: false,
      bulkUser: false,
      profileImg: false,
      counselingCategory: false,
      counselingChannel: false,
      counselingCounselor: false,
      classDepartment: false,
      classParentInfo: false,
      classParent: false,
      classStudent: false,
      classTeacher: false,
    },
  },
};

export const toggleSlice = createSlice({
  name: 'toggle',
  initialState,
  reducers: {
    initModals: (state) => {
      state.modals = initialState.modals;
    },
    // 사이드바
    toggleSideBar: (state) => {
      state.sideBar = !state.sideBar;
    },
    // 커스텀 모달
    modalController: (
      state,
      { payload: { name, secondName, value } }: { payload: { name: string; secondName: string; value: boolean } },
    ) => {
      state.modals[name][secondName] = value;
    },
    // react-modal (라이브러리)
    reactModalController: (
      state,
      { payload: { name, secondName, value } }: { payload: { name: string; secondName: string; value: boolean } },
    ) => {
      state.reactModals[name][secondName] = value;
    },
  },
});

export const { toggleSideBar, initModals, modalController, reactModalController } = toggleSlice.actions;

export const getSideBarToggleValue = (state: RootState) => state.toggle.sideBar;

export default toggleSlice.reducer;
